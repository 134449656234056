import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import ChatWindow from './ChatWindow';



const cookies = new Cookies()

export default function Messages() {
  const [role, setRole] = useState("");
  const [userId, setUserId] = useState("");
  const [conversations, setConversations] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [unread, setUnread] = useState(0);



  useEffect(() => {
    checkCookie();
  }, []);

  async function checkCookie() {
    let response = await axios.get(
      process.env.REACT_APP_TEST_URL + "/api/get/readCookie",
      { withCredentials: true }
    );

    if(response.data.role !== undefined) {
      setRole(response.data.role)
    }

    if(response.data.id !== undefined) {
      setUserId(response.data.id)
    }

    if(response.data.jwt === undefined) {
      window.location.replace("/");
    }
  }

  function showUnread(count){
    setUnread(count);
  }
  useEffect(() => {
    async function fetchMessages() {
      try {
        if(userId !== undefined || role !== undefined) {

          const response = await axios.get(process.env.REACT_APP_TEST_URL+'/api/get/fetch-messages',{ params: { userId, role } });
          if(response.data){
            /*various people the person is talking to*/
            setConversations(response.data)
            setFetched(true);
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchMessages()
  }, [userId])

  if (conversations.length === 0) {
    return (
      <div className="messages-container">
        <div>Ingen meldinger</div>
      </div>
    )
  } else {
    return (
      <div className="messages-container">
        {conversations.map((conversation, index) => {
          let latestdate = conversation.latestDate.slice(11,16);
          let latestdateDay = conversation.latestDate.slice(8,10);
          let latestdateMonth = conversation.latestDate.slice(5,7);
          return (
            <div className='messages__message' key={index}>
              <button className='message__summary' onClick={(e)=>{e.currentTarget.nextElementSibling.classList.toggle('show'); e.currentTarget.classList.toggle('open');}}>
                <span className='summary__sender'>{conversation.firstName}</span><span className='summary__latest-date'>{latestdateDay}.{latestdateMonth} - {latestdate}</span> 
              </button>          
              <ChatWindow senderId={userId} receiverId={conversation.iduser.toString()} showUnread={showUnread} />
            </div>
          )
        })}
      </div>
    )
  }
}
