import rating from "../../functions/rating";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Calendar from "react-calendar";
import { differenceInCalendarDays } from "date-fns";
import ChatWindow from "./ChatWindow";
const cookies = new Cookies();

export default function Bookings() {
  const [role, setRole] = useState();
  const [userId, setUserId] = useState();
  const [bookings, setBookings] = useState([]);
  const [bookingDates, setBookingDates] = useState([]);
  const [starArray, setStarArray] = useState([]);
  const [displayContainer, setDisplayContainer] = useState(false);
  const [cancelBookingModal, setCancelBookingModal] = useState(false);
  const [stars, setStars] = useState(0);
  const [count, setCount] = useState(0);
  const [ratingCleaner, setRatingCleaner] = useState(0);
  const [beskrivelse, setBeskrivelse] = useState();
  const [cleaner, setCleaner] = useState("");
  const [booking, setBooking] = useState("");
  const [chatmodal, setChatmodal] = useState(false);

  const [bookingId, setBookingId] = useState();

  let icon = rating(stars)[0];
  let aria = rating(stars)[1];

  useEffect(() => {
    checkCookie();
  }, [userId]);

  useEffect(() => {
    if (cancelBookingModal) {
      window.scrollTo(0, 0);
    }
  });

  async function checkCookie() {
    let response = await axios.get(
      process.env.REACT_APP_TEST_URL + "/api/get/readCookie",
      { withCredentials: true }
    );


    if (response.data.role !== undefined) {
      setRole(response.data.role);
    }
    if (response.data.id !== undefined) {
      setUserId(response.data.id);
    }

    if(response.data.jwt === undefined) {
      window.location.replace("/");
    }

    if (role === "cleaner") {
      fetchBookingsCleaner();
    } else {
      fetchBookingsUser();
    }
  }

  function logChange(e) {}

  async function giveRating(e, date) {
    let currentDate = new Date();
    let bookingDate = new Date(date);
    if (currentDate <= bookingDate) {
      window.scrollTo(0, 0);
      let cleanerId = e.target.parentElement.parentElement.id;

      let bookingId = e.target.parentElement.parentElement.className;
      bookingId = bookingId.match(/\d+/g)[0];
      setCleaner(cleanerId);
      setBooking(bookingId);

      if (!displayContainer) {
        document.getElementsByClassName("ratingContainer")[0].style.display =
          "flex";
        setDisplayContainer(true);
      }

      if (displayContainer) {
        document.getElementsByClassName("ratingContainer")[0].style.display =
          "none";
        setDisplayContainer(false);
      }
    } else {
    }

    // if()
  }
  async function cancelBooking(e) {
    axios
      .post(process.env.REACT_APP_TEST_URL + "/api/post/cancelBooking", {
        idbooking: bookingId, role: role
      })
      .then((result) => {
        if (result.data) {
          setCancelBookingModal(false);
          window.location.reload();
        }
      });
  }

  const handleLeave = (e) => {
    setStars(starArray.length);
  };
  const handleEnter = (e) => {
    setStars(0);
  };

  const handleHover = (e) => {
    e.persist();

    let name = e.target.className.animVal;

    if (name.includes("star")) {
      if (starArray.length <= 0) {
        starArray.push(name);
      } else {
        if (starArray.length > 0 && !starArray.includes(name)) {
          starArray.push(name);
        }
      }
    }
  };

  async function handleRating(e) {
    document.getElementById(e.id).style.backgroundColor = "#1a94ff";
    document.getElementById(e.id).style.color = "white";
    document.getElementById("feedbackRating").style.display = "none";

    setRatingCleaner(e.id);

    if (ratingCleaner > 0) {
      document.getElementById(ratingCleaner).style.backgroundColor = "white";
      document.getElementById(ratingCleaner).style.color = "#282b2e";
      setRatingCleaner(e.id);
    } else {
      setRatingCleaner(e.id);
      document.getElementById(e.id).style.backgroundColor = "#1a94ff";
      document.getElementById(e.id).style.color = "white";
      document.getElementById("feedbackRating").style.display = "none";
    }
  }

  function isSameDay(a, b) {
    return differenceInCalendarDays(a, b) === 0;
  }

  function ToggleBar(e) {
    let sibling = e.target.nextElementSibling.classList.toggle("hide");
    e.target.lastChild.classList.toggle("arrowDown");
  }

  const tileClassName = ({ date }) => {
    const isBooked = bookingDates.some(
      (session) => session.toDateString() === date.toDateString()
    );
    return isBooked ? "booked" : "";
  };

  // function tileClassName(date, view) {
  //   console.log("tileClass")
  //   // Add class to tiles in month view only
  //   let datesToAddClassTo = bookingDates;
  //   console.log(date, view, "VIEWDATE");
  //   console.log(datesToAddClassTo);
  //   if (view === 'month') {
  //     // Check if a date React-Calendar wants to check is on the list of dates to add class to
  //     if (datesToAddClassTo.find(dDate => isSameDay(dDate, date))) {
  //       console.log(date);

  //       return 'myClassName';
  //     }
  //   }
  // }

  async function submitRating() {
    if (ratingCleaner > 0) {
      document.getElementById("ratingButton").innerHTML = "Vurderer";
      axios
        .post(process.env.REACT_APP_TEST_URL + "/api/post/submitRating", {
          rating: ratingCleaner,
          cleanerId: cleaner,
          userId: userId,
          idBookings: booking,
          beskrivelse: beskrivelse,
        })
        .then((result) => {
          if (result.status === 200) {
            document.getElementById("thankYouRating").innerHTML = result.data;
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        });
    } else {
      document.getElementById("feedbackRating").style.display = "block";
    }
  }

  if (!bookings) {
    return (
      <div className="bookings-container">
        <Calendar
          onChange={(date) => logChange(date)}
          tileClassName={tileClassName}
          minDate={new Date("01-01-2024")}
        />
        <div>Ingen bookinger</div>
      </div>
    );
  } else {
    {
    }

    if (role === "cleaner") {
      return (
        <div className="bookings-container">
          <Calendar
            locale="no-NO"
            // minDate={new Date()}
            minDate={new Date("01-01-2024")}
            onClickDay={(value) => logChange(value)}
            tileClassName={tileClassName}
            // tileDisabled={({ date }) =>
            //   bookingDates.some(
            //     (bookingDate) =>
            //       date.getFullYear() === bookingDate.getFullYear() &&
            //       date.getMonth() === bookingDate.getMonth() &&
            //       date.getDate() === bookingDate.getDate()
            //   )
            // }
            //  [bookingDates.getDate()].includes(date.getDate())}
          />
          <h3 onClick={(e) => ToggleBar(e)}>
            Bookinger <span id="arrow">&#8595;</span>
          </h3>
          <div>
            {bookings.map((booking) => {
              let bookingDate = new Date(booking.date);
              if (bookingDate > Date.now() && booking.cancelled !== 1) {
                return (
                  <div
                    className="bookingField"
                    id={booking.bookings_idbookings}
                  >
                    <div className="flexRow">
                      <p>
                        Dato: {new Date(booking.date).toLocaleDateString()}{" "}
                      </p>
                      <p>Tid: {booking.time}</p>
                      <p>
                        Navn: {booking.firstName} {booking.lastName}{" "}
                      </p>
                      <p>
                        {" "}
                        Adresse: {booking.address}, {booking.zipcode}{" "}
                        {booking.county}{" "}
                      </p>
                      <button
                        className="cancel"
                        onClick={(e) => {

                          setCancelBookingModal(true);
                          setBookingId(booking.bookings_idbookings);
                        }}
                      >
                        Kanseller
                      </button>
                      <div className="button-container" id={booking.customerid}>
                        <button
                          className="chat"
                          id="chat-button"
                          onClick={(e) => {
                            setChatmodal(true);
                            document.getElementsByClassName(
                              "chat-modal"
                            )[0].style.display = "block";
                          }}
                        >
                          Chat
                        </button>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <h3 onClick={(e) => ToggleBar(e)}>
            Gjennomførte <span id="arrow">&#8595;</span>
          </h3>
          <div>
            {bookings.map((booking) => {
              let bookingDate = new Date(booking.date);
              if (bookingDate < Date.now() && booking.cancelled !== 1) {
                return (
                  <div
                    className="bookingField"
                    id={booking.bookings_idbookings}
                  >
                    <div className="flexRow">
                      <p>
                        Dato: {new Date(booking.date).toLocaleDateString()}{" "}
                      </p>
                      <p>Tid: {booking.time}</p>
                      <p>
                        Navn: {booking.firstName} {booking.lastName}{" "}
                      </p>
                      <p>
                        {" "}
                        Adresse: {booking.address}, {booking.zipcode}{" "}
                        {booking.county}{" "}
                      </p>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <h3 onClick={(e) => ToggleBar(e)}>
            Kansellerte <span id="arrow">&#8595;</span>
          </h3>
          <div>
            {bookings.map((booking) => {
              if (booking.cancelled === 1) {
                return (
                  <div
                    className="bookingField"
                    id={booking.bookings_idbookings}
                  >
                    <div className="flexRow">
                      <div>
                        <p>
                          Dato: {new Date(booking.date).toLocaleDateString()}{" "}
                        </p>
                        <p>Tid: {booking.time}</p>
                        <p>
                          Navn: {booking.firstName} {booking.lastName}{" "}
                        </p>
                        <p>
                          {" "}
                          Adresse: {booking.address}, {booking.zipcode}{" "}
                          {booking.county}{" "}
                        </p>
                      </div>
                      <div className="button-container" id={booking.customerid}>
                        {/* <button
                          className="chat"
                          id="chat-button"
                          onClick={(e) => {
                            setChatmodal(true);
                            document.getElementsByClassName(
                              "chat-modal"
                            )[0].style.display = "block";
                          }}
                        >
                          Chat
                        </button> */}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>

          {chatmodal ? (
            <ChatWindow receiverId={242} senderId={userId} modal={true} />
          ) : (
            <div></div>
          )}

          {cancelBookingModal ? (
            <div className="overlay">
              <div className="deleteModal">
                <p>
                  Er du helt sikker på at du vil kansellere avtalen med
                  kunden? Kunden vil da få pengene refundert. 
                </p>
                <div>
                  <button onClick={cancelBooking}>Ja, avbestill timen</button>
                  <button onClick={() => setCancelBookingModal(false)}>
                    Nei, ikke avbestill!
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      );
    } else {
      return (
        <div className="bookings-container">
          <Calendar
            locale="no-NO"
            // minDate={new Date()}
            onChange={(date) => logChange(date)}
            minDate={new Date("01-01-2024")}
            tileClassName={tileClassName}
            // tileDisabled={({ date }) =>
            //   bookingDates.some(
            //     (bookingDate) =>
            //       date.getFullYear() === bookingDate.getFullYear() &&
            //       date.getMonth() === bookingDate.getMonth() &&
            //       date.getDate() === bookingDate.getDate()
            //   )
            // }
          />

          <h2>Bestilte timer</h2>

          {bookings.map((booking) => {
            if (booking.cancelled !== 1) {
              if (booking.isRated !== 0 && booking.isRated !== null) {
                return (
                  // Så langt har jeg kommet
                  <div
                    className={`bookingField ratingField ${booking.bookings_idbookings}`}
                    id={booking.cleanerid}
                  >
                    <div className="flexRow spaceBetween">
                      <p>Dato: {new Date(booking.date).toLocaleDateString()}</p>
                      <p> Tid: {booking.time} </p>
                      <p>
                        {" "}
                        <Link to={`/renholder/${booking.cleanerid}`}>
                          {" "}
                          Navn: {booking.firstName} {booking.lastName}
                        </Link>
                      </p>
                    </div>
                    <button className="ratedButton">Vurdert</button>
                  </div>
                );
              } else {
                return (
                  <div
                    className={`bookingField ${booking.bookings_idbookings}`}
                    id={booking.cleanerid}
                  >
                    <p>
                      Dato: {new Date(booking.date).toLocaleDateString()} Tid:{" "}
                      {booking.time} Navn: {booking.firstName}{" "}
                      {booking.lastName}
                    </p>
                    <div>
                      <button
                        onClick={(e) => {
                          setCancelBookingModal(true);
                          setBookingId(booking.bookings_idbookings);
                        }}
                      >
                        Avbestill
                      </button>
                      <button onClick={(e) => giveRating(e, booking.date)}>
                        Vurder
                      </button>
                    </div>
                  </div>
                );
              }
            }
          })}

          <h2>Avbestilte timer</h2>

          {bookings.map((booking) => {
            if (booking.cancelled === 1) {
              if (booking.isRated !== 0 && booking.isRated !== null) {
                return (
                  // Så langt har jeg kommet
                  <div
                    className={`bookingField ratingField ${booking.bookings_idbookings}`}
                    id={booking.cleanerid}
                  >
                    <p>
                      Dato: {new Date(booking.date).toLocaleDateString()} Tid:{" "}
                      {booking.time} Navn: {booking.firstName}{" "}
                      {booking.lastName}
                    </p>
                    <button className="ratedButton">Vurdert</button>
                  </div>
                );
              } else {
                return (
                  <div
                    className={`bookingField ${booking.cleanerid}`}
                    id={booking.bookings_idbookings}
                  >
                    <p>
                      Dato: {new Date(booking.date).toLocaleDateString()} Tid:{" "}
                      {booking.time} Navn: {booking.firstName}{" "}
                      {booking.lastName}
                    </p>
                  </div>
                );
              }
            }
          })}

          <div className="ratingContainer">
            <h2>Vurder renholder</h2>
            <p>Gi en rating til personen som har rengjort hjemmet ditt</p>
            <div className="profile__rating" aria-label={aria}>
              <h4>Vurdering: </h4>

              <div
                className="rating"
                // onMouseOver={handleHover}
                // onMouseLeave={handleLeave}
                // onMouseEnter={handleEnter}
                onClick={(e) => handleRating(e.target)}
              >
                <div id="1">1</div>
                <div id="2">2</div>
                <div id="3">3</div>
                <div id="4">4</div>
                <div id="5">5</div>
                {/* {icon} */}
              </div>

              <textarea
                name="vurdering"
                id="vurdering"
                cols="25"
                rows="5"
                onKeyUp={(e) => {
                  setBeskrivelse(e.target.value);
                }}
              ></textarea>
              <button
                id="ratingButton"
                onClick={submitRating}
                className="ratingButton"
              >
                Vurder
              </button>
              <p id="feedbackRating">Vurdering ikke gitt</p>
            </div>
            <p id="thankYouRating"></p>
          </div>

          {cancelBookingModal ? (
            <div className="overlay">
              <div className="deleteModal">
                <p>
                  Er du helt sikker på at du vil kansellere avtalen med
                  renholderen? Vennligst vær oppmerksom på at dersom du
                  kansellerer mindre enn 24 timer før avtalt tidspunkt, vil du
                  ikke få refundert pengene.
                </p>
                <div>
                  <button onClick={cancelBooking}>Ja, avbestill timen</button>
                  <button onClick={() => setCancelBookingModal(false)}>
                    Nei, ikke avbestill!
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      );
    }
  }

  async function fetchBookingsCleaner() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_TEST_URL + "/api/get/fetch-bookings-cleaner",
        { params: { userId, role } }
      );

      if (response.data) {
        setBookings(response.data);
        response.data.forEach((element) => {
          if (element.cancelled !== 1) {
            let newDate = new Date(element.date);
            bookingDates.push(newDate);
          }
        });
      }
    } catch (error) {}
  }
  async function fetchBookingsUser() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_TEST_URL + "/api/get/fetch-bookings-customer",
        { params: { userId, role } }
      );

      if (response.data) {
        setBookings(response.data);

        response.data.forEach((element) => {
          if (element.cancelled !== 1) {
            let newDate = new Date(element.date);
            bookingDates.push(newDate);
          }
        });
      }
    } catch (error) {}
  }
}

// cancelBooking(e, booking.bookings_idbookings)
