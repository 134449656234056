// export default function RenholderOverview() {
//   return (
//     <div>
//       <h1>Oversikt renholdere</h1>
//     </div>
//   );
// }

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
export default function RenholderOverview() {
  const [isAdmin, setIsAdmin] = useState();
  const [cleaners, setCleaners] = useState();
  const [areas, setAreas] = useState();
  const [businessName, setBusinessName] = useState();
  const [loading, setLoading] = useState(false); // Initialize loading state

  useEffect(() => {
    // checkCookie();

    getCleaners();
  }, []);

  // async function checkCookie() {
  //   let response = await axios.get(
  //     process.env.REACT_APP_TEST_URL + "/api/get/readCookie",
  //     { withCredentials: true }
  //   );
  //   if (response.data.jwt !== undefined) {

  //     let isAdminQuery = await axios.post(
  //       process.env.REACT_APP_TEST_URL + "/isAdmin",
  //       { jwtToken: response.data.jwt },
  //       { withCredentials: true }
  //     );

  //     setIsAdmin(isAdminQuery.data);
  //   }
  // }

  async function getCleaners() {
    axios
      .get(process.env.REACT_APP_TEST_URL + "/api/get/cleaners", {
        withCredentials: true,
      })
      .then((result) => {
        if (result.data == "hello") {
          window.location.replace("/");
        } else {
          let cleanerArray = result.data;
          setCleaners(cleanerArray);

          cleanerArray.forEach((cleaner) => {
            checkReg(cleaner);
            cleaner.area = [];
            getAreas(cleaner);
          });
        }
      });
  }

  async function getAreas(cleaner) {
    let idcleaner = cleaner.idcleaner;
    axios
      .get(process.env.REACT_APP_TEST_URL + "/api/get/areas", { params: {idcleaner},
        withCredentials: true,
      })
      .then((result) => {
        if (result.data == "hello") {
          window.location.replace("/");
        } else {
          let areaArray = result.data;
          setAreas(areaArray);

          areaArray.forEach((area) => {
            // checkReg(cleaner);
            // setCleaners(...area)
            cleaner.area.push(area.cityName);
          });
        }
      });
  }

  async function checkReg(cleaner) {
    try {
      const response = await axios.get(
        "https://data.brreg.no/enhetsregisteret/api/enheter/" +
          cleaner.orgNumber
      );
      if (
        response.status === 200 &&
        !response.data.konkurs &&
        !response.data.underTvangsavviklingEllerTvangsopplosning
      ) {
        document.getElementById(`${cleaner.idcleaner}`).innerHTML =
          response.data.navn;
      }
    } catch (error) {
      if (error.response.status === 404) {
        return "undefined";
      } else {
        return "undefined";
      }
    }
  }

  // if(!loading) {
  if (cleaners) {
    return (
      <div className="adminDashboard">
        <div className="backButton">
          <Link to="/adminDashboard">Tilbake</Link>
        </div>
        <h1>Oversikt Renholdere</h1>
        <div className="lengthContainer">Antall: {cleaners.length}</div>
        <div className="flexContainerAdmin">
          {cleaners.map((cleaner) => {
            let area = cleaner.area;
            return (
              <div className="flexCard" key={cleaner.idcleaner}>
                <div className="">
                  {cleaner.firstName} {cleaner.lastName}
                </div>
                <div className="">{cleaner.email}</div>
                <div className="">{cleaner.phonenumber}</div>
                <div id={cleaner.idcleaner}></div>
                <div className="">Rating: {cleaner.currentRating}</div>
                <div className="">Timesbetaling: {cleaner.hourlyPay}</div>
                {area.map((aa) => {
                  return <div className=""><span>Områder: {aa}</span></div>
                }) }
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

//   if(!isAdmin){

//       return <div></div>;
//   }
// }
// else {
//   return (<div></div>)
// }}
