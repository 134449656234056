import { Link } from "react-router-dom"

export default function Footer() {
  return(
    <div className="footer">
      <div className="footer__decoration-one"></div>
      <div className="footer__decoration-two"></div>
      <div className="footer__title">Housemaids</div>
      <div className="footer__links-container">
        <Link to='logginn'>Logg inn</Link>
        <Link to='faq'>Hvordan fungerer det</Link>
        <Link to='retningslinjer'>Retningslinjer</Link>
        <Link to='ressurser'>Ressurser</Link>
        <Link to='om'>Om oss</Link>
        <Link to='kontakt'>Ta kontakt</Link>
        <Link>Org nr 930 704 369 </Link>
        <Link></Link>
        <a href='https://www.exuma.no'>En del av Exuma AS</a>
      </div>
    </div>
  )
}
