import { Link } from "react-router-dom";
import Dustpan from '../images/mobile-hero-image.jpg';

export default function NyBruker() {
  return(
    <div className="nybruker-page">
      <h1>Ny Bruker</h1>
      <h2>Så flott at du ønsker å lage ny bruker! Men først, er du renholder eller kunde?</h2>
      <div className="selection-container">
        <div className="selection__img-container"><img src={Dustpan} alt="feiebrett som henger på veggen" /></div>
        <div className="selection__links-container">
          <div className="link-container"><Link to='/nyrenholder' >Renholder</Link></div>
          <div className="link-container"><Link to='/nykunde'>Kunde</Link></div>
        </div>
      </div>
      
    </div>
  )
}
