import { useState, useEffect } from "react";
import MiniForm from "../micro/MiniForm";
import axios from "axios";
import Cookies from "universal-cookie";

export default function KundeProfil({ profiledata }) {
  const [notification, setNotification] = useState("");
  const [profileImage, setProfileImage] = useState();
  const [fetched, setFetched] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  let cookies = new Cookies();
  const [userId, setUserId] = useState();


  useEffect(() => {
    checkCookie();
    fetchProfilePicture();
  }, []);

  async function checkCookie() {
    let response = await axios.get(
      process.env.REACT_APP_TEST_URL + "/api/get/readCookie",
      { withCredentials: true }
    );

    // if (response.data.jwt !== undefined) {
    //   setLoggedIn(true);
    // }
    // if(response.data.role !== undefined) {
    //   setRole(response.data.role)
    // }

    if (response.data.id !== undefined) {
      setUserId(response.data.id);
    }
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];

   
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };


  async function fetchProfilePicture() {
    if (Object.keys(profiledata).length <= 0) {
      axios
        .post(
          process.env.REACT_APP_TEST_URL + "/api/get/profileImage",
          { userId: userId },
          { withCredentials: true }
        )
        .then((result) => {
          const timestamp = new Date().getTime();
          setProfileImage(`${result.data}?${timestamp}`);
          // setProfileImage(result.data);
        });
    } else {
      // setProfileImage(profiledata.profilephoto);
      const timestamp = new Date().getTime();
      setProfileImage(`${profiledata.profilephoto}?${timestamp}`);
    }
  }

  function handleClick(event) {
    const inputField =
      event.currentTarget.nextElementSibling.firstElementChild
        .nextElementSibling;
    event.currentTarget.parentElement.classList.toggle("show-form");
    inputField.focus();
    if (event.currentTarget.lastChild.data) {
      inputField.defaultValue = `${event.currentTarget.lastChild.data}`;
    }
  }

  function handlePicture(event) {
    if (event.currentTarget.className === "clickable-picture") {
      document.getElementById("formImage").style.visibility = "inherit";
    }
  }

  async function imageUpload(e) {
    e.preventDefault();

    let file = e.target[0].files[0];

    // let file = e.target[0].files[0];

    const formData = new FormData();
    formData.append("image", file);
    formData.append("name", profiledata.firstName + profiledata.lastName);
    try {
      const response = await axios.post(
        process.env.REACT_APP_TEST_URL + "/api/post/profileImage",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        // setProfileImage(response.data);
        // window.location.reload();
        document.getElementById("formImage").style.visibility ="hidden";
      }
    } catch (error) {
    } finally {
      setFetched(false);
      e.target.parentElement.classList.toggle("show-form");
    }
  }

  function deleteAccount() {
    axios
      .post(process.env.REACT_APP_TEST_URL + "/api/post/deleteAccount", {
        iduser: profiledata.iduser,
      })
      .then((result) => {
        if (result.data) {
          axios
            .get(process.env.REACT_APP_TEST_URL + "/api/post/deleteCookie", {
              withCredentials: true,
            })
            .then((result) => {
              if (result.data) {
                window.location.replace("/");
              }
            });
        }
      });
  }

  return (
    <div className="profile-container">
      <h1 className="profile-container__name">
        {profiledata.firstName} {profiledata.lastName} - Profil
      </h1>
      <p>Klikk for å endre detaljene.</p>
      <div className="profile-container__detail">
        <div
          className="clickable-picture"
          onClick={handlePicture}
          tabIndex="0"
        >
          <div className="widthProfile">
            <img
              className="profilephoto"
              src={profileImage}
              alt="profilbilde"
            />
            <div class="imageBlob"></div>
            <form id="formImage" onSubmit={imageUpload}>
              <label htmlFor="image">Velg bilde fil</label>
              <input type="file" id="image" name="image" accept="image/*"  onChange={handleImageChange} />
              <button>Last opp</button>
            </form>
          </div>
        </div>

        <div className="infoBesidePicture customer">

            <MiniForm
              dataName="epost"
              label="Epost"
              id={profiledata.id}
              role={profiledata.role} defaultInput={profiledata.email}
            />

            <MiniForm
              dataName="telefon"
              label="Telefon"
              id={profiledata.id}
              role={profiledata.role}
              defaultInput={profiledata.phonenumber}
            />
      
            <MiniForm
              dataName="adresseGate"
              label="Adresse"
              id={profiledata.id}
              role={profiledata.role} defaultInput={profiledata.address}
            />
            <MiniForm
              dataName="postnummer"
              label="Postnummer"
              id={profiledata.id}
              role={profiledata.role}
              defaultInput= {profiledata.zipCode}
            />
            <div className="county-detail">
              <div>By:</div>
              <div>{profiledata.county}</div>
            </div>
          <div className="deleteAccount" onClick={() => setDeleteModal(true)}>
        Slett konto
      </div>
        </div>
      </div>



      <div className="profile-notification">{notification}</div>

      {deleteModal ? (
        <div className="overlay">
          <div className="deleteModal">
            <p>Er du sikker på at du ønsker å slette profilen din?</p>
            <div>
              <button onClick={deleteAccount}>Ja, slett bruker</button>
              <button onClick={() => setDeleteModal(false)}>
                Nei, ikke slett meg!
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
