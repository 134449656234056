import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
export default function KundeOverview() {
  const [isAdmin, setIsAdmin] = useState();
  const [customers, setCustomers] = useState();

  useEffect(() => {
    // checkCookie();

    checkCustomers();
  }, []);

  async function checkCustomers() {
    axios
      .get(process.env.REACT_APP_TEST_URL + "/api/get/customers", {
        withCredentials: true,
      })
      .then((result) => {
        if (result.data == "hello") {
          window.location.replace("/");
        } else {
          let customerArray = result.data;
          setCustomers(customerArray);
        }
      });
  }

  if (customers) {
    return (
      <div className="adminDashboard">
        <div className="backButton">
          <Link to="/adminDashboard">Tilbake</Link>
        </div>
        <h1>Kunde Oversikt</h1>
        <div className="lengthContainer">Antall: {customers.length}</div>
        <div className="flexContainerAdmin">
          {customers.map((customer) => {
            return (
              <div className="flexCard">
                <div>
                  {customer.firstName} {customer.lastName}
                </div>
                <div>{customer.email}</div>
                <div>{customer.phonenumber}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  // if (!isAdmin) {
  //   return <div></div>;
  // }
}
