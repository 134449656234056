import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
export default function BookingOverview() {
  const [isAdmin, setIsAdmin] = useState();
  const [bookings, setBookings] = useState();

  useEffect(() => {
    // checkCookie();

      checkBookings();
    
  }, []);



  async function checkBookings() {
    axios
    .get(process.env.REACT_APP_TEST_URL + "/api/get/bookings", {withCredentials: true})
    .then((result) => {
      if(result.data == "hello") {
        window.location.replace("/");
      } else {
        let bookingsArray = result.data;
        setBookings(bookingsArray);
      }
    
    });
  }

  if (bookings) {
    return (
      <div className="adminDashboard">
        <div className="backButton"><Link to="/adminDashboard">Tilbake</Link></div>
        <h1>Oversikt Bookinger</h1>
        <div className="flexContainerAdmin">
          {bookings.map((booking) => {
            return(
              <div className="flexCard" id={booking.idbooking}>
                <div>Dato: {booking.date.slice(0,10)}</div>
                <div>Tid: {booking.time}</div>
                <div>Sted: {booking.bookingName}</div>
                <div>Vurdert? {booking.isRated ? booking.isRated : "Nei"}</div>
                <div>Bruker: {booking.customerid}</div>
                <div>Renholder: {booking.cleanerid}</div>
                <div>Gjentagende? {booking.repeatName}</div>
              </div>
            )
          } )}
        </div>
      </div>
    );
  } 
  

}
