import axios from 'axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import Dustpan from '../images/mobile-hero-image.jpg'
import { useState, useContext } from 'react'
import AuthContext from '../context/AuthContext'
import { useSearchParams } from "react-router-dom";
import { Link } from 'react-router-dom'
import sha256 from 'crypto-js/sha256'
var bcrypt = require('bcryptjs');
var salt = bcrypt.genSaltSync(10);

const cookies = new Cookies()

let SHA256 = require('crypto-js/sha256')

const schema = yup.object().shape({
  passord: yup.string().required('Vennligst skriv inn et nytt passord'),
})

export default function NyttPassord() {
  const navigate = useNavigate()
  const [passwordError, setPasswordError] = useState('')
  const [searchParams, setSearchParams] = useSearchParams();
  const [updated, setUpdated] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  async function onSubmit(data) {



    let path = window.location.href;
    let identification = path.slice(40,)
    let decrypted = identification

    let cryptedPassword = bcrypt.hashSync(data.passord, salt);


    let a = `http://localhost:3000/nyttPassord?email=`

    try {
      const idFetch = await axios.get(
        process.env.REACT_APP_TEST_URL+'/api/get/nytt-passord',
        {
          params: { epost: `${decrypted}`, p: `${cryptedPassword}` },
        },
      )
      if (idFetch.data.length > 0) {
        setUpdated(true);
      } else {
        setPasswordError('Prøv på nytt')
      }
    } catch (error) {
      console.log(error)
    }
  }

  if(updated){
    return (
      <div className='password-update-success'>
        <h2>Passordet er oppdatert</h2>
        <Link to="/logginn" className='login-button'>Logg inn</Link>
      </div>
    ) 
  }

  return (
    <div className="logginn-page">
      <h1>Lag et nytt passord</h1>
      <div className="logginn-container">
        <div className="logginn__img-container">
          <img src={Dustpan} alt="feiebrett som henger på veggen" />
        </div>

        <form id="logginn__form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-item">
            <label htmlFor="passord">Passord</label>
            <input {...register('passord')} type="password" />
            {errors.passord && (
              <span className="form__error logginn-form__error">
                {errors.passord.message}
              </span>
            )}
            {passwordError && (
              <span className="form__error logginn-form__error">
                {passwordError}
              </span>
            )}
          </div>
          <div className="button-container">
            <button>Nytt passord</button>
          </div>
        </form>
      </div>
    </div>
  )
}
