export const blackCat = <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 64 64" viewBox="0 0 64 64" id="black-cat"><g><g><path d="M16.28,60.41c-0.91,0-1.69-0.15-2.31-0.57c-0.29-0.22-0.44-0.54-0.41-0.88c0.03-0.35,0.23-0.65,0.53-0.81l2.52-1.31
			c3.2-9.79,2.46-16.74-2.31-21.86c-2.66-3.29-3.54-7.79-2.62-13.41c0.11-0.64,0.27-1.29,0.49-1.92c1.01-2.89-1.43-4.56-1.45-4.57
			c-3.37-2.03-2.35-5.47-1.96-6.48c0.18-0.46,0.64-0.76,1.13-0.74l1.53,0.07l0.14,0c0.7,0,1.35-0.21,1.9-0.6
			c1.03-0.73,2.33-1,3.57-0.74l2.76,0.6l4.02-3.24c0.14-0.12,0.33-0.16,0.51-0.13c0.18,0.03,0.34,0.14,0.43,0.3
			c0.08,0.13,1.86,3.28,0.19,6.48h0c0,0-1.18,2.94-0.44,5.44c0.3,0.98,0.44,1.81,0.42,2.6l-0.05,2.59
			c16.22,6.03,24.39,16.73,23.02,30.17c-0.04,0.34-0.34,0.6-0.69,0.56c-0.34-0.03-0.6-0.34-0.56-0.69
			c1.69-16.57-11.37-24.92-22.62-29.01c-0.25-0.09-0.42-0.33-0.41-0.6l0.06-3.04c0.01-0.66-0.1-1.36-0.36-2.21
			c-0.88-2.95,0.45-6.2,0.51-6.33c0.96-1.84,0.52-3.68,0.16-4.64l-3.62,2.92C20.19,8.49,20,8.54,19.81,8.5l-3.05-0.66
			c-0.9-0.19-1.84,0.01-2.58,0.53c-0.76,0.54-1.67,0.83-2.63,0.83h-0.17L9.89,9.13c-0.39,1.03-0.94,3.43,1.49,4.9
			c1.16,0.77,3.03,3.01,1.97,6.05c-0.2,0.56-0.35,1.14-0.44,1.71c-0.85,5.25-0.07,9.42,2.33,12.38c5.13,5.49,5.92,12.91,2.48,23.32
			c-0.05,0.16-0.16,0.28-0.31,0.36l-2.28,1.19c0.96,0.3,2.55,0.06,4.23-0.24c0.6-0.11,1.13-0.53,1.36-1.1
			c2.55-6.13,4.01-11,4.58-15.32c0.05-0.34,0.36-0.59,0.7-0.54c0.34,0.05,0.59,0.36,0.54,0.7c-0.59,4.43-2.07,9.4-4.66,15.64
			c-0.4,0.97-1.26,1.66-2.3,1.85C18.39,60.23,17.26,60.41,16.28,60.41z"></path></g><g><path d="M28.22,48.79c-0.18,0-0.36-0.08-0.49-0.23l-2.7-3.3c-0.22-0.27-0.18-0.66,0.09-0.88c0.27-0.22,0.66-0.18,0.88,0.09
			l2.7,3.3c0.22,0.27,0.18,0.66-0.09,0.88C28.5,48.75,28.36,48.79,28.22,48.79z"></path></g><g><path d="M2.51,50.67C1.12,50.67,0,49.54,0,48.16v-2.5c0-1.29,0.48-2.5,1.36-3.41c1.64-1.7,4.4-3.08,8.67-4.37
			c2.84-0.75,2.83-5.22,2.83-5.27c0-0.35,0.28-0.63,0.62-0.63c0,0,0,0,0.01,0c0.34,0,0.62,0.28,0.63,0.62c0,0.22,0,5.5-3.75,6.49
			c-4.04,1.21-6.62,2.5-8.11,4.03c-0.65,0.67-1.01,1.57-1.01,2.53v2.5c0,0.69,0.56,1.25,1.25,1.25c0.61,0,1.13-0.43,1.23-1.03
			l0.41-2.32c0.18-1.03,1.02-1.82,2.07-1.96c5.37-0.72,9.17-2.1,11.62-4.23c0.26-0.23,0.66-0.2,0.89,0.06
			c0.23,0.26,0.2,0.66-0.06,0.89c-2.63,2.29-6.65,3.78-12.27,4.53c-0.51,0.07-0.91,0.45-1,0.94L4.98,48.6
			C4.76,49.8,3.73,50.67,2.51,50.67z"></path></g><g><path d="M16.89,7.85c-0.16,0-0.32-0.06-0.44-0.18c-0.25-0.24-0.25-0.64,0-0.89l3.03-3.04c0.15-0.15,0.38-0.22,0.58-0.17
			c0.21,0.05,0.38,0.2,0.45,0.41l1.01,2.92c0.11,0.33-0.06,0.68-0.39,0.8c-0.33,0.11-0.68-0.06-0.8-0.39l-0.68-1.96l-2.32,2.33
			C17.22,7.79,17.05,7.85,16.89,7.85z"></path></g><g><g><path d="M47.11,60.45c-0.23,0-0.46,0-0.69-0.01c-0.01,0-0.02,0-0.02,0c-0.12,0-0.27-0.01-0.41-0.02l-20.61,0
				c-1.23,0-2.23-1.01-2.23-2.24c0-1.1,0.81-2.05,1.89-2.22l4.07-0.61c-1.57-2.3-2.1-4.88-1.49-7.33c0.65-2.65,2.54-4.69,4.94-5.32
				c2.57-0.67,5.27,0.34,7.6,2.84c0.24,0.25,0.22,0.65-0.03,0.89c-0.25,0.24-0.65,0.22-0.89-0.03c-2-2.15-4.26-3.03-6.37-2.48
				c-1.95,0.51-3.5,2.2-4.04,4.4c-0.54,2.2,0,4.55,1.52,6.62c0.21,0.28,0.26,0.66,0.12,1c-0.14,0.33-0.43,0.55-0.78,0.6l-4.47,0.67
				c-0.47,0.07-0.83,0.49-0.83,0.98c0,0.54,0.44,0.99,0.98,0.99h20.65c0.01,0,0.02,0,0.03,0c1.56-3.2,0.55-7.61,0.54-7.66
				c-0.04-0.19,0-0.38,0.12-0.53c0.12-0.15,0.3-0.24,0.49-0.24c2.91,0.04,5.31-0.98,6.64-2.8c1.17-1.62,1.39-3.75,0.62-6
				c-0.46-1.31-1.29-2.53-2.46-3.62c-6.37-5.97-11.33-20.32-4.59-29.25c2.01-2.66,5.24-4.18,8.63-4.08
				c1.64,0.05,2.98,0.29,4.09,0.72c2.71,1.06,4.1,4.14,3.1,6.87c-0.6,1.62-2.19,3.31-6.43,2.12c-0.27-0.08-0.54-0.14-0.8-0.18
				l-0.18-0.03c-0.7-0.11-1.36,0.3-1.58,0.97c-2.34,7.02-0.74,12.77,4.89,17.57c0.19,0.17,0.37,0.31,0.56,0.45l0.11,0.08
				c4.09,3.14,5.32,9.07,3.13,15.11C60.94,54.17,55.62,60.45,47.11,60.45z M47.97,51.98c0.23,1.41,0.54,4.53-0.55,7.21
				c7.69-0.16,12.5-5.91,14.33-10.94c2-5.51,0.93-10.89-2.71-13.69l-0.1-0.08c-0.22-0.16-0.43-0.33-0.63-0.51
				c-5.97-5.08-7.74-11.45-5.25-18.91c0.42-1.25,1.66-2.01,2.96-1.81l0.19,0.03c0.32,0.05,0.64,0.12,0.95,0.21
				c4,1.13,4.69-0.73,4.91-1.34c0.76-2.09-0.31-4.45-2.38-5.27c-0.97-0.38-2.2-0.59-3.67-0.64c-2.98-0.1-5.82,1.24-7.59,3.58
				c-6.32,8.37-1.59,21.92,4.45,27.57c1.32,1.22,2.26,2.61,2.79,4.12c0.91,2.65,0.63,5.19-0.79,7.15
				C53.42,50.67,50.93,51.86,47.97,51.98z M29.34,55.68C29.34,55.68,29.34,55.68,29.34,55.68C29.34,55.68,29.34,55.68,29.34,55.68z
				 M29.52,55.29C29.52,55.29,29.52,55.29,29.52,55.29L29.52,55.29z"></path></g></g></g></svg>