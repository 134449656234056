import Messages from "../components/global/Messages"

export default function KundeMessages() {
  return(
    <div>
      <h1>Meldinger</h1>
      <Messages />
    </div>
  )
}
