import axios from 'axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import Dustpan from '../images/mobile-hero-image.jpg'
import { useState, useContext, useEffect } from 'react'
import AuthContext from '../context/AuthContext'
import { Link } from 'react-router-dom'



const cookies = new Cookies()
let date = new Date()
var bcrypt = require('bcryptjs')
var salt = bcrypt.genSaltSync(10)

let SHA256 = require('crypto-js/sha256')

const schema = yup.object().shape({
  epost: yup
    .string()
    .required('Vennligst oppgi eposten din')
    .email('Vennligst skriv inn en gyldig e-postadresse'),
  passord: yup.string().required('Vennligst oppgi passordet ditt'),
})

export default function Logginn() {
  const [loggedIn, setLoggedIn] = useContext(AuthContext)
  const navigate = useNavigate()
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    checkCookie();
    window.scrollTo(0, 0);
  }, [])
  


  async function checkCookie() {
    let response = await axios.get(process.env.REACT_APP_TEST_URL+'/api/get/readCookie', {withCredentials: true})
    if(response.data.jwt !== undefined) {
      // navigate("/finnrenholder")
      window.location.replace("/finnrenholder");
      
    }

  }

  // async function checkPayment() {
  //   let response = await axios.get(process.env.REACT_APP_TEST_URL+'/api/get/send-payment')
  //   let info = response.data;

  //   info.forEach(async (element) => {
  //     if (
  //       (element.paidout === 0 || element.paidout === null) &&
  //       element.date < date.toISOString()
  //     ) {
  //       let days =
  //         ((new Date(date) - new Date(element.date)) * 60 * 24) / 1000 / 60
  //       let stripeAccount = element.stripeAccount
  //       let amount = element.totalprice
  //       let response2 = await axios.post(
  //         process.env.REACT_APP_TEST_URL+'/api/post/payOut',
  //         { stripeaccount: stripeAccount, amount: amount },
  //       )

  //     }

      
  //   })
  //   return info;
  // }
  async function onSubmit(data) {
    // data.event.preventDefault();

    document.getElementById("loggInn").innerHTML = "Logger inn";
    try {
      const idFetch = await axios.get(process.env.REACT_APP_TEST_URL+'/api/get/hent-id', {
        params: { epost: `${data.epost}` }, withCredentials: true
      })

      if (idFetch.data.length > 0) {
        let hashedPassord = data.passord
        await axios
          .get(process.env.REACT_APP_TEST_URL+'/api/post/send-passord', {
            params: {
              hashedPassord: `${hashedPassord}`,
              epost: `${data.epost}`,
            },
          })
          .then(async (response) => {
            if (
              response.data.password !== undefined ||
              response.data.password !== null
            ) {
              bcrypt.compare(hashedPassord, response.data.password, async function (
                err,
                res,
              ) {
                if (res === true) {
                  await axios.post(process.env.REACT_APP_TEST_URL+'/api/post/setCookie', {
                    role: response.data.cleaner_idcleaner,
                    iduser: response.data.iduser
                  }, {withCredentials: true}).then((result) => {
                  })
        
                  // cookies.set('id', response.data.iduser, {
                  //   path: '/',
                  //   maxAge: 604800,
                  // })
                  // if (response.data.cleaner_idcleaner === null) {
                  //   cookies.set('role', 'customer', {
                  //     path: '/',
                  //     maxAge: 604800,
                  //   })
                  // } else {
                  //   cookies.set('role', 'cleaner', {
                  //     path: '/',
                  //     maxAge: 604800,
                  //   })
                  // }

                  if (response.data.cleaner_idcleaner === null) {
                    setLoggedIn(true)
                    window.location.replace("/finnrenholder");
                    // checkPayment()
                  } else {
                    setLoggedIn(true)
                    window.location.replace("/profil")
                  }
                } else {
                  setPasswordError('Passordet er feil, vennligst prøv igjen')
                  document.getElementById("loggInn").innerHTML  = "Logg inn"
                }
              })
            }

          })
      } else {
        setEmailError('Kan ikke finne brukeren, lag gjerne en ny bruker')
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="logginn-page">
      <h1>Logg inn</h1>
      <div className="logginn-container">
        <div className="logginn__img-container">
          <img src={Dustpan} alt="feiebrett som henger på veggen" />
        </div>
        <form id="logginn__form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-item">
            <label htmlFor="epost">E-post</label>
            <input {...register('epost')} />
            {errors.epost && (
              <span className="form__error logginn-form__error">
                {errors.epost.message}
              </span>
            )}
            {emailError && (
              <span className="form__error logginn-form__error">
                {emailError}
              </span>
            )}
          </div>
          <div className="form-item">
            <label htmlFor="passord">Passord</label>
            <input {...register('passord')} type="password" />
            {errors.passord && (
              <span className="form__error logginn-form__error">
                {errors.passord.message}
              </span>
            )}
            {passwordError && (
              <span className="form__error logginn-form__error">
                {passwordError}
              </span>
            )}
          </div>
          <div>
            <Link to="/nybruker">Ny bruker?</Link>
            <Link to="/glemtPassord">Glemt passord?</Link>
          </div>
          <div className="button-container">
            <button id="loggInn">Logg Inn</button>
          </div>
        </form>
      </div>
    </div>
  )
}
