import MyComponent from "./newEditor";
import { useEffect, useState } from "react";
import axios from "axios";
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

export default function CreateArticle() {
  const [bodyValue, setBodyValue] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {

  })

  useEffect(() => {
    checkCookie();
  }, []);

  async function checkCookie() {


      let isAdminQuery = await axios.get(
        process.env.REACT_APP_TEST_URL + "/isAdmin",
        { withCredentials: true }
      );


      if(isAdminQuery.data == "hello") {
        window.location.replace("/");
      } else {
        setIsAdmin(isAdminQuery.data);

      }

    }
  const schema = yup.object().shape({
    title: yup.string().required("vennligst legg inn en tittel til innlegget"),
    param: yup.string().required("vennligst legg inn en parameter til innlegget"),
    heroImage: yup
      .string()
      .required("vennligst legg inn en hero image til innlegget"),
    metatitle: yup.string().required("meta tittel er bra for Google"),
    metadesc: yup
      .string()
      .required(
        "øk konverteringen med en god beskrivelse som får folk til å lese innlegget"
      ),
    // body: yup.string().required("jo lengre jo bedre, men husk kvalitet!"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });


  if(isAdmin)  {

    return (
      <div className="createArticlePage">
      {" "}
      <div className="employeeTrailCont">
      </div>
      <h1 className="sectionHeadder">Lag blogginnlegg</h1>
      <div id="bloggEditor">
        <MyComponent />
      </div>
    </div>
  ) 
}
  else {
    return <div></div>
  }

  // async function trackChange(text) {
  //   let body = document.getElementById("bodyElement").value;
  //   body += text;

  //   setBodyValue(body);
  //   // return body;
  // }

  // async function handleTag(e) {
  //   let tag = e.target.innerHTML;
  //   if (tag === "h1") {
  //     trackChange("<br><h1></h1>");
  //   } else if (tag === "h2") {
  //     trackChange("<br><h2></h2>");
  //   } else if (tag === "h3") {
  //     trackChange("<br><h3></h3>");
  //   } else if (tag === "h4") {
  //     trackChange("<br><h4></h4>");
  //   } else if (tag === "bold") {
  //     trackChange("<strong></strong>");
  //   } else if (tag === "italics") {
  //     trackChange("<i></i>");
  //   }
  // }
  // async function onSubmit(data) {
  //   const blogg = {
  //     title: data.title,
  //     heroImage: data.heroImage,
  //     metatitle: data.metatitle,
  //     metadesc: data.metadesc,
  //     body: bodyValue,
  //   };

  //   axios
  //     .post(`${process.env.REACT_APP_APP_URL}` + "createBlog", blogg)
  //     .then(() => {});
  // }
}
  