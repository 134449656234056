export const noStars = <svg width="150" height="30" viewBox="0 0 150 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g  clipPath="url(#clip0_247_1375)">
    <path className="iconStar star1" d="M27.5 11.55L18.5125 10.775L15 2.5L11.4875 10.7875L2.5 11.55L9.325 17.4625L7.275 26.25L15 21.5875L22.725 26.25L20.6875 17.4625L27.5 11.55ZM15 19.25L10.3 22.0875L11.55 16.7375L7.4 13.1375L12.875 12.6625L15 7.625L17.1375 12.675L22.6125 13.15L18.4625 16.75L19.7125 22.1L15 19.25Z" fill="white"/>
  </g>
  <g icoclipPath="url(#clip1_247_1375)">
    <path className="iconStar star2" d="M57.5 11.55L48.5125 10.775L45 2.5L41.4875 10.7875L32.5 11.55L39.325 17.4625L37.275 26.25L45 21.5875L52.725 26.25L50.6875 17.4625L57.5 11.55ZM45 19.25L40.3 22.0875L41.55 16.7375L37.4 13.1375L42.875 12.6625L45 7.625L47.1375 12.675L52.6125 13.15L48.4625 16.75L49.7125 22.1L45 19.25Z" fill="white"/>
  </g>
  <g clipPath="url(#clip2_247_1375)">
    <path  className="iconStar star3" d="M87.5 11.55L78.5125 10.775L75 2.5L71.4875 10.7875L62.5 11.55L69.325 17.4625L67.275 26.25L75 21.5875L82.725 26.25L80.6875 17.4625L87.5 11.55ZM75 19.25L70.3 22.0875L71.55 16.7375L67.4 13.1375L72.875 12.6625L75 7.625L77.1375 12.675L82.6125 13.15L78.4625 16.75L79.7125 22.1L75 19.25Z" fill="white"/>
  </g>
  <g  clipPath="url(#clip3_247_1375)">
    <path className="iconStar star4" d="M147.5 11.55L138.513 10.775L135 2.5L131.487 10.7875L122.5 11.55L129.325 17.4625L127.275 26.25L135 21.5875L142.725 26.25L140.687 17.4625L147.5 11.55ZM135 19.25L130.3 22.0875L131.55 16.7375L127.4 13.1375L132.875 12.6625L135 7.625L137.138 12.675L142.613 13.15L138.463 16.75L139.713 22.1L135 19.25Z" fill="white"/>
  </g>
  <g clipPath="url(#clip4_247_1375)">
    <path className="iconStar star5" d="M117.5 11.55L108.513 10.775L105 2.5L101.487 10.7875L92.5 11.55L99.325 17.4625L97.275 26.25L105 21.5875L112.725 26.25L110.687 17.4625L117.5 11.55ZM105 19.25L100.3 22.0875L101.55 16.7375L97.4 13.1375L102.875 12.6625L105 7.625L107.138 12.675L112.613 13.15L108.463 16.75L109.713 22.1L105 19.25Z" fill="white"/>
  </g>
  <defs>
  <clipPath id="clip0_247_1375">
  <rect width="30" height="30" fill="white"/>
  </clipPath>
  <clipPath id="clip1_247_1375">
  <rect width="30" height="30" fill="white" transform="translate(30)"/>
  </clipPath>
  <clipPath id="clip2_247_1375">
  <rect width="30" height="30" fill="white" transform="translate(60)"/>
  </clipPath>
  <clipPath id="clip3_247_1375">
  <rect width="30" height="30" fill="white" transform="translate(120)"/>
  </clipPath>
  <clipPath id="clip4_247_1375">
  <rect width="30" height="30" fill="white" transform="translate(90)"/>
  </clipPath>
  </defs>
</svg>

export const halfStar = <svg width="149" height="30" viewBox="0 0 149 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_247_1197)">
<path d="M26.5832 11.55L17.8953 10.775L14.4998 2.5L11.1044 10.7875L2.4165 11.55L9.014 17.4625L7.03234 26.25L14.4998 21.5875L21.9673 26.25L19.9978 17.4625L26.5832 11.55ZM14.4998 19.25V7.625L16.5661 12.675L21.8586 13.15L17.8469 16.75L19.0553 22.1L14.4998 19.25Z" fill="white"/>
</g>
<g clipPath="url(#clip1_247_1197)">
<path d="M56.5 11.55L47.5125 10.775L44 2.5L40.4875 10.7875L31.5 11.55L38.325 17.4625L36.275 26.25L44 21.5875L51.725 26.25L49.6875 17.4625L56.5 11.55ZM44 19.25L39.3 22.0875L40.55 16.7375L36.4 13.1375L41.875 12.6625L44 7.625L46.1375 12.675L51.6125 13.15L47.4625 16.75L48.7125 22.1L44 19.25Z" fill="white"/>
</g>
<g clipPath="url(#clip2_247_1197)">
<path d="M86.5 11.55L77.5125 10.775L74 2.5L70.4875 10.7875L61.5 11.55L68.325 17.4625L66.275 26.25L74 21.5875L81.725 26.25L79.6875 17.4625L86.5 11.55ZM74 19.25L69.3 22.0875L70.55 16.7375L66.4 13.1375L71.875 12.6625L74 7.625L76.1375 12.675L81.6125 13.15L77.4625 16.75L78.7125 22.1L74 19.25Z" fill="white"/>
</g>
<g clipPath="url(#clip3_247_1197)">
<path d="M116.5 11.55L107.513 10.775L104 2.5L100.487 10.7875L91.5 11.55L98.325 17.4625L96.275 26.25L104 21.5875L111.725 26.25L109.687 17.4625L116.5 11.55ZM104 19.25L99.3 22.0875L100.55 16.7375L96.4 13.1375L101.875 12.6625L104 7.625L106.138 12.675L111.613 13.15L107.463 16.75L108.713 22.1L104 19.25Z" fill="white"/>
</g>
<g clipPath="url(#clip4_247_1197)">
<path d="M146.5 11.55L137.513 10.775L134 2.5L130.487 10.7875L121.5 11.55L128.325 17.4625L126.275 26.25L134 21.5875L141.725 26.25L139.687 17.4625L146.5 11.55ZM134 19.25L129.3 22.0875L130.55 16.7375L126.4 13.1375L131.875 12.6625L134 7.625L136.138 12.675L141.613 13.15L137.463 16.75L138.713 22.1L134 19.25Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_247_1197">
<rect width="29" height="30" fill="white"/>
</clipPath>
<clipPath id="clip1_247_1197">
<rect width="30" height="30" fill="white" transform="translate(29)"/>
</clipPath>
<clipPath id="clip2_247_1197">
<rect width="30" height="30" fill="white" transform="translate(59)"/>
</clipPath>
<clipPath id="clip3_247_1197">
<rect width="30" height="30" fill="white" transform="translate(89)"/>
</clipPath>
<clipPath id="clip4_247_1197">
<rect width="30" height="30" fill="white" transform="translate(119)"/>
</clipPath>
</defs>
</svg>

export const oneStar = <svg width="150" height="30" viewBox="0 0 150 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_247_1238)">
<path d="M57.5 11.55L48.5125 10.775L45 2.5L41.4875 10.7875L32.5 11.55L39.325 17.4625L37.275 26.25L45 21.5875L52.725 26.25L50.6875 17.4625L57.5 11.55ZM45 19.25L40.3 22.0875L41.55 16.7375L37.4 13.1375L42.875 12.6625L45 7.625L47.1375 12.675L52.6125 13.15L48.4625 16.75L49.7125 22.1L45 19.25Z" fill="white"/>
</g>
<g clipPath="url(#clip1_247_1238)">
<path d="M87.5 11.55L78.5125 10.775L75 2.5L71.4875 10.7875L62.5 11.55L69.325 17.4625L67.275 26.25L75 21.5875L82.725 26.25L80.6875 17.4625L87.5 11.55ZM75 19.25L70.3 22.0875L71.55 16.7375L67.4 13.1375L72.875 12.6625L75 7.625L77.1375 12.675L82.6125 13.15L78.4625 16.75L79.7125 22.1L75 19.25Z" fill="white"/>
</g>
<g clipPath="url(#clip2_247_1238)">
<path d="M117.5 11.55L108.513 10.775L105 2.5L101.487 10.7875L92.5 11.55L99.325 17.4625L97.275 26.25L105 21.5875L112.725 26.25L110.687 17.4625L117.5 11.55ZM105 19.25L100.3 22.0875L101.55 16.7375L97.4 13.1375L102.875 12.6625L105 7.625L107.138 12.675L112.613 13.15L108.463 16.75L109.713 22.1L105 19.25Z" fill="white"/>
</g>
<g clipPath="url(#clip3_247_1238)">
<path d="M147.5 11.55L138.513 10.775L135 2.5L131.487 10.7875L122.5 11.55L129.325 17.4625L127.275 26.25L135 21.5875L142.725 26.25L140.687 17.4625L147.5 11.55ZM135 19.25L130.3 22.0875L131.55 16.7375L127.4 13.1375L132.875 12.6625L135 7.625L137.138 12.675L142.613 13.15L138.463 16.75L139.713 22.1L135 19.25Z" fill="white"/>
</g>
<g clipPath="url(#clip4_247_1238)">
<path d="M15 21.5875L22.725 26.25L20.675 17.4625L27.5 11.55L18.5125 10.7875L15 2.5L11.4875 10.7875L2.5 11.55L9.325 17.4625L7.275 26.25L15 21.5875Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_247_1238">
<rect width="30" height="30" fill="white" transform="translate(30)"/>
</clipPath>
<clipPath id="clip1_247_1238">
<rect width="30" height="30" fill="white" transform="translate(60)"/>
</clipPath>
<clipPath id="clip2_247_1238">
<rect width="30" height="30" fill="white" transform="translate(90)"/>
</clipPath>
<clipPath id="clip3_247_1238">
<rect width="30" height="30" fill="white" transform="translate(120)"/>
</clipPath>
<clipPath id="clip4_247_1238">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>

export const oneHalfStar = <svg width="150" height="30" viewBox="0 0 150 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_247_1217)">
<path d="M15 21.5875L22.725 26.25L20.675 17.4625L27.5 11.55L18.5125 10.7875L15 2.5L11.4875 10.7875L2.5 11.55L9.325 17.4625L7.275 26.25L15 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip1_247_1217)">
<path d="M57.5832 11.55L48.8953 10.775L45.4998 2.5L42.1044 10.7875L33.4165 11.55L40.014 17.4625L38.0323 26.25L45.4998 21.5875L52.9673 26.25L50.9978 17.4625L57.5832 11.55ZM45.4998 19.25V7.625L47.5661 12.675L52.8586 13.15L48.8469 16.75L50.0553 22.1L45.4998 19.25Z" fill="white"/>
</g>
<g clipPath="url(#clip2_247_1217)">
<path d="M87.5 11.55L78.5125 10.775L75 2.5L71.4875 10.7875L62.5 11.55L69.325 17.4625L67.275 26.25L75 21.5875L82.725 26.25L80.6875 17.4625L87.5 11.55ZM75 19.25L70.3 22.0875L71.55 16.7375L67.4 13.1375L72.875 12.6625L75 7.625L77.1375 12.675L82.6125 13.15L78.4625 16.75L79.7125 22.1L75 19.25Z" fill="white"/>
</g>
<g clipPath="url(#clip3_247_1217)">
<path d="M117.5 11.55L108.513 10.775L105 2.5L101.487 10.7875L92.5 11.55L99.325 17.4625L97.275 26.25L105 21.5875L112.725 26.25L110.687 17.4625L117.5 11.55ZM105 19.25L100.3 22.0875L101.55 16.7375L97.4 13.1375L102.875 12.6625L105 7.625L107.138 12.675L112.613 13.15L108.463 16.75L109.713 22.1L105 19.25Z" fill="white"/>
</g>
<g clipPath="url(#clip4_247_1217)">
<path d="M147.5 11.55L138.513 10.775L135 2.5L131.487 10.7875L122.5 11.55L129.325 17.4625L127.275 26.25L135 21.5875L142.725 26.25L140.687 17.4625L147.5 11.55ZM135 19.25L130.3 22.0875L131.55 16.7375L127.4 13.1375L132.875 12.6625L135 7.625L137.138 12.675L142.613 13.15L138.463 16.75L139.713 22.1L135 19.25Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_247_1217">
<rect width="30" height="30" fill="white"/>
</clipPath>
<clipPath id="clip1_247_1217">
<rect width="29" height="30" fill="white" transform="translate(31)"/>
</clipPath>
<clipPath id="clip2_247_1217">
<rect width="30" height="30" fill="white" transform="translate(60)"/>
</clipPath>
<clipPath id="clip3_247_1217">
<rect width="30" height="30" fill="white" transform="translate(90)"/>
</clipPath>
<clipPath id="clip4_247_1217">
<rect width="30" height="30" fill="white" transform="translate(120)"/>
</clipPath>
</defs>
</svg>

export const twoStars =  <svg width="150" height="30" viewBox="0 0 150 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_247_1271)">
<path d="M15 21.5875L22.725 26.25L20.675 17.4625L27.5 11.55L18.5125 10.7875L15 2.5L11.4875 10.7875L2.5 11.55L9.325 17.4625L7.275 26.25L15 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip1_247_1271)">
<path d="M45 21.5875L52.725 26.25L50.675 17.4625L57.5 11.55L48.5125 10.7875L45 2.5L41.4875 10.7875L32.5 11.55L39.325 17.4625L37.275 26.25L45 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip2_247_1271)">
<path d="M87.5 11.55L78.5125 10.775L75 2.5L71.4875 10.7875L62.5 11.55L69.325 17.4625L67.275 26.25L75 21.5875L82.725 26.25L80.6875 17.4625L87.5 11.55ZM75 19.25L70.3 22.0875L71.55 16.7375L67.4 13.1375L72.875 12.6625L75 7.625L77.1375 12.675L82.6125 13.15L78.4625 16.75L79.7125 22.1L75 19.25Z" fill="white"/>
</g>
<g clipPath="url(#clip3_247_1271)">
<path d="M117.5 11.55L108.513 10.775L105 2.5L101.487 10.7875L92.5 11.55L99.325 17.4625L97.275 26.25L105 21.5875L112.725 26.25L110.687 17.4625L117.5 11.55ZM105 19.25L100.3 22.0875L101.55 16.7375L97.4 13.1375L102.875 12.6625L105 7.625L107.138 12.675L112.613 13.15L108.463 16.75L109.713 22.1L105 19.25Z" fill="white"/>
</g>
<g clipPath="url(#clip4_247_1271)">
<path d="M147.5 11.55L138.513 10.775L135 2.5L131.487 10.7875L122.5 11.55L129.325 17.4625L127.275 26.25L135 21.5875L142.725 26.25L140.687 17.4625L147.5 11.55ZM135 19.25L130.3 22.0875L131.55 16.7375L127.4 13.1375L132.875 12.6625L135 7.625L137.138 12.675L142.613 13.15L138.463 16.75L139.713 22.1L135 19.25Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_247_1271">
<rect width="30" height="30" fill="white"/>
</clipPath>
<clipPath id="clip1_247_1271">
<rect width="30" height="30" fill="white" transform="translate(30)"/>
</clipPath>
<clipPath id="clip2_247_1271">
<rect width="30" height="30" fill="white" transform="translate(60)"/>
</clipPath>
<clipPath id="clip3_247_1271">
<rect width="30" height="30" fill="white" transform="translate(90)"/>
</clipPath>
<clipPath id="clip4_247_1271">
<rect width="30" height="30" fill="white" transform="translate(120)"/>
</clipPath>
</defs>
</svg>

export const twoHalfStars = <svg width="150" height="30" viewBox="0 0 150 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_247_1289)">
<path d="M15 21.5875L22.725 26.25L20.675 17.4625L27.5 11.55L18.5125 10.7875L15 2.5L11.4875 10.7875L2.5 11.55L9.325 17.4625L7.275 26.25L15 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip1_247_1289)">
<path d="M45 21.5875L52.725 26.25L50.675 17.4625L57.5 11.55L48.5125 10.7875L45 2.5L41.4875 10.7875L32.5 11.55L39.325 17.4625L37.275 26.25L45 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip2_247_1289)">
<path d="M87.5832 11.55L78.8953 10.775L75.4998 2.5L72.1044 10.7875L63.4165 11.55L70.014 17.4625L68.0323 26.25L75.4998 21.5875L82.9673 26.25L80.9978 17.4625L87.5832 11.55ZM75.4998 19.25V7.625L77.5661 12.675L82.8586 13.15L78.8469 16.75L80.0553 22.1L75.4998 19.25Z" fill="white"/>
</g>
<g clipPath="url(#clip3_247_1289)">
<path d="M117.5 11.55L108.513 10.775L105 2.5L101.487 10.7875L92.5 11.55L99.325 17.4625L97.275 26.25L105 21.5875L112.725 26.25L110.687 17.4625L117.5 11.55ZM105 19.25L100.3 22.0875L101.55 16.7375L97.4 13.1375L102.875 12.6625L105 7.625L107.138 12.675L112.613 13.15L108.463 16.75L109.713 22.1L105 19.25Z" fill="white"/>
</g>
<g clipPath="url(#clip4_247_1289)">
<path d="M147.5 11.55L138.513 10.775L135 2.5L131.487 10.7875L122.5 11.55L129.325 17.4625L127.275 26.25L135 21.5875L142.725 26.25L140.687 17.4625L147.5 11.55ZM135 19.25L130.3 22.0875L131.55 16.7375L127.4 13.1375L132.875 12.6625L135 7.625L137.138 12.675L142.613 13.15L138.463 16.75L139.713 22.1L135 19.25Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_247_1289">
<rect width="30" height="30" fill="white"/>
</clipPath>
<clipPath id="clip1_247_1289">
<rect width="30" height="30" fill="white" transform="translate(30)"/>
</clipPath>
<clipPath id="clip2_247_1289">
<rect width="29" height="30" fill="white" transform="translate(61)"/>
</clipPath>
<clipPath id="clip3_247_1289">
<rect width="30" height="30" fill="white" transform="translate(90)"/>
</clipPath>
<clipPath id="clip4_247_1289">
<rect width="30" height="30" fill="white" transform="translate(120)"/>
</clipPath>
</defs>
</svg>

export const threeStars = <svg width="150" height="30" viewBox="0 0 150 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_247_1311)">
<path d="M15 21.5875L22.725 26.25L20.675 17.4625L27.5 11.55L18.5125 10.7875L15 2.5L11.4875 10.7875L2.5 11.55L9.325 17.4625L7.275 26.25L15 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip1_247_1311)">
<path d="M45 21.5875L52.725 26.25L50.675 17.4625L57.5 11.55L48.5125 10.7875L45 2.5L41.4875 10.7875L32.5 11.55L39.325 17.4625L37.275 26.25L45 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip2_247_1311)">
<path d="M75 21.5875L82.725 26.25L80.675 17.4625L87.5 11.55L78.5125 10.7875L75 2.5L71.4875 10.7875L62.5 11.55L69.325 17.4625L67.275 26.25L75 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip3_247_1311)">
<path d="M117.5 11.55L108.513 10.775L105 2.5L101.487 10.7875L92.5 11.55L99.325 17.4625L97.275 26.25L105 21.5875L112.725 26.25L110.687 17.4625L117.5 11.55ZM105 19.25L100.3 22.0875L101.55 16.7375L97.4 13.1375L102.875 12.6625L105 7.625L107.138 12.675L112.613 13.15L108.463 16.75L109.713 22.1L105 19.25Z" fill="white"/>
</g>
<g clipPath="url(#clip4_247_1311)">
<path d="M147.5 11.55L138.513 10.775L135 2.5L131.487 10.7875L122.5 11.55L129.325 17.4625L127.275 26.25L135 21.5875L142.725 26.25L140.687 17.4625L147.5 11.55ZM135 19.25L130.3 22.0875L131.55 16.7375L127.4 13.1375L132.875 12.6625L135 7.625L137.138 12.675L142.613 13.15L138.463 16.75L139.713 22.1L135 19.25Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_247_1311">
<rect width="30" height="30" fill="white"/>
</clipPath>
<clipPath id="clip1_247_1311">
<rect width="30" height="30" fill="white" transform="translate(30)"/>
</clipPath>
<clipPath id="clip2_247_1311">
<rect width="30" height="30" fill="white" transform="translate(60)"/>
</clipPath>
<clipPath id="clip3_247_1311">
<rect width="30" height="30" fill="white" transform="translate(90)"/>
</clipPath>
<clipPath id="clip4_247_1311">
<rect width="30" height="30" fill="white" transform="translate(120)"/>
</clipPath>
</defs>
</svg>

export const threeHalfStars = <svg width="150" height="30" viewBox="0 0 150 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_247_1330)">
<path d="M15 21.5875L22.725 26.25L20.675 17.4625L27.5 11.55L18.5125 10.7875L15 2.5L11.4875 10.7875L2.5 11.55L9.325 17.4625L7.275 26.25L15 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip1_247_1330)">
<path d="M45 21.5875L52.725 26.25L50.675 17.4625L57.5 11.55L48.5125 10.7875L45 2.5L41.4875 10.7875L32.5 11.55L39.325 17.4625L37.275 26.25L45 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip2_247_1330)">
<path d="M75 21.5875L82.725 26.25L80.675 17.4625L87.5 11.55L78.5125 10.7875L75 2.5L71.4875 10.7875L62.5 11.55L69.325 17.4625L67.275 26.25L75 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip3_247_1330)">
<path d="M117.583 11.55L108.895 10.775L105.5 2.5L102.104 10.7875L93.4165 11.55L100.014 17.4625L98.0323 26.25L105.5 21.5875L112.967 26.25L110.998 17.4625L117.583 11.55ZM105.5 19.25V7.625L107.566 12.675L112.859 13.15L108.847 16.75L110.055 22.1L105.5 19.25Z" fill="white"/>
</g>
<g clipPath="url(#clip4_247_1330)">
<path d="M147.5 11.55L138.513 10.775L135 2.5L131.487 10.7875L122.5 11.55L129.325 17.4625L127.275 26.25L135 21.5875L142.725 26.25L140.687 17.4625L147.5 11.55ZM135 19.25L130.3 22.0875L131.55 16.7375L127.4 13.1375L132.875 12.6625L135 7.625L137.138 12.675L142.613 13.15L138.463 16.75L139.713 22.1L135 19.25Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_247_1330">
<rect width="30" height="30" fill="white"/>
</clipPath>
<clipPath id="clip1_247_1330">
<rect width="30" height="30" fill="white" transform="translate(30)"/>
</clipPath>
<clipPath id="clip2_247_1330">
<rect width="30" height="30" fill="white" transform="translate(60)"/>
</clipPath>
<clipPath id="clip3_247_1330">
<rect width="29" height="30" fill="white" transform="translate(91)"/>
</clipPath>
<clipPath id="clip4_247_1330">
<rect width="30" height="30" fill="white" transform="translate(120)"/>
</clipPath>
</defs>
</svg>

export const fourStars = <svg width="150" height="30" viewBox="0 0 150 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_247_1353)">
<path d="M15 21.5875L22.725 26.25L20.675 17.4625L27.5 11.55L18.5125 10.7875L15 2.5L11.4875 10.7875L2.5 11.55L9.325 17.4625L7.275 26.25L15 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip1_247_1353)">
<path d="M45 21.5875L52.725 26.25L50.675 17.4625L57.5 11.55L48.5125 10.7875L45 2.5L41.4875 10.7875L32.5 11.55L39.325 17.4625L37.275 26.25L45 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip2_247_1353)">
<path d="M75 21.5875L82.725 26.25L80.675 17.4625L87.5 11.55L78.5125 10.7875L75 2.5L71.4875 10.7875L62.5 11.55L69.325 17.4625L67.275 26.25L75 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip3_247_1353)">
<path d="M105 21.5875L112.725 26.25L110.675 17.4625L117.5 11.55L108.513 10.7875L105 2.5L101.487 10.7875L92.5 11.55L99.325 17.4625L97.275 26.25L105 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip4_247_1353)">
<path d="M147.5 11.55L138.513 10.775L135 2.5L131.487 10.7875L122.5 11.55L129.325 17.4625L127.275 26.25L135 21.5875L142.725 26.25L140.687 17.4625L147.5 11.55ZM135 19.25L130.3 22.0875L131.55 16.7375L127.4 13.1375L132.875 12.6625L135 7.625L137.138 12.675L142.613 13.15L138.463 16.75L139.713 22.1L135 19.25Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_247_1353">
<rect width="30" height="30" fill="white"/>
</clipPath>
<clipPath id="clip1_247_1353">
<rect width="30" height="30" fill="white" transform="translate(30)"/>
</clipPath>
<clipPath id="clip2_247_1353">
<rect width="30" height="30" fill="white" transform="translate(60)"/>
</clipPath>
<clipPath id="clip3_247_1353">
<rect width="30" height="30" fill="white" transform="translate(90)"/>
</clipPath>
<clipPath id="clip4_247_1353">
<rect width="30" height="30" fill="white" transform="translate(120)"/>
</clipPath>
</defs>
</svg>

export const fourHalfStars = <svg width="150" height="30" viewBox="0 0 150 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_247_1173)">
<path d="M15 21.5875L22.725 26.25L20.675 17.4625L27.5 11.55L18.5125 10.7875L15 2.5L11.4875 10.7875L2.5 11.55L9.325 17.4625L7.275 26.25L15 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip1_247_1173)">
<path d="M45 21.5875L52.725 26.25L50.675 17.4625L57.5 11.55L48.5125 10.7875L45 2.5L41.4875 10.7875L32.5 11.55L39.325 17.4625L37.275 26.25L45 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip2_247_1173)">
<path d="M75 21.5875L82.725 26.25L80.675 17.4625L87.5 11.55L78.5125 10.7875L75 2.5L71.4875 10.7875L62.5 11.55L69.325 17.4625L67.275 26.25L75 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip3_247_1173)">
<path d="M105 21.5875L112.725 26.25L110.675 17.4625L117.5 11.55L108.513 10.7875L105 2.5L101.487 10.7875L92.5 11.55L99.325 17.4625L97.275 26.25L105 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip4_247_1173)">
<path d="M147.583 11.55L138.895 10.775L135.5 2.5L132.104 10.7875L123.417 11.55L130.014 17.4625L128.032 26.25L135.5 21.5875L142.967 26.25L140.998 17.4625L147.583 11.55ZM135.5 19.25V7.625L137.566 12.675L142.859 13.15L138.847 16.75L140.055 22.1L135.5 19.25Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_247_1173">
<rect width="30" height="30" fill="white"/>
</clipPath>
<clipPath id="clip1_247_1173">
<rect width="30" height="30" fill="white" transform="translate(30)"/>
</clipPath>
<clipPath id="clip2_247_1173">
<rect width="30" height="30" fill="white" transform="translate(60)"/>
</clipPath>
<clipPath id="clip3_247_1173">
<rect width="30" height="30" fill="white" transform="translate(90)"/>
</clipPath>
<clipPath id="clip4_247_1173">
<rect width="29" height="30" fill="white" transform="translate(121)"/>
</clipPath>
</defs>
</svg>

export const fiveStars = <svg width="150" height="30" viewBox="0 0 150 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_247_1152)">
<path d="M15 21.5875L22.725 26.25L20.675 17.4625L27.5 11.55L18.5125 10.7875L15 2.5L11.4875 10.7875L2.5 11.55L9.325 17.4625L7.275 26.25L15 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip1_247_1152)">
<path d="M45 21.5875L52.725 26.25L50.675 17.4625L57.5 11.55L48.5125 10.7875L45 2.5L41.4875 10.7875L32.5 11.55L39.325 17.4625L37.275 26.25L45 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip2_247_1152)">
<path d="M75 21.5875L82.725 26.25L80.675 17.4625L87.5 11.55L78.5125 10.7875L75 2.5L71.4875 10.7875L62.5 11.55L69.325 17.4625L67.275 26.25L75 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip3_247_1152)">
<path d="M105 21.5875L112.725 26.25L110.675 17.4625L117.5 11.55L108.513 10.7875L105 2.5L101.487 10.7875L92.5 11.55L99.325 17.4625L97.275 26.25L105 21.5875Z" fill="white"/>
</g>
<g clipPath="url(#clip4_247_1152)">
<path d="M135 21.5875L142.725 26.25L140.675 17.4625L147.5 11.55L138.513 10.7875L135 2.5L131.487 10.7875L122.5 11.55L129.325 17.4625L127.275 26.25L135 21.5875Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_247_1152">
<rect width="30" height="30" fill="white"/>
</clipPath>
<clipPath id="clip1_247_1152">
<rect width="30" height="30" fill="white" transform="translate(30)"/>
</clipPath>
<clipPath id="clip2_247_1152">
<rect width="30" height="30" fill="white" transform="translate(60)"/>
</clipPath>
<clipPath id="clip3_247_1152">
<rect width="30" height="30" fill="white" transform="translate(90)"/>
</clipPath>
<clipPath id="clip4_247_1152">
<rect width="30" height="30" fill="white" transform="translate(120)"/>
</clipPath>
</defs>
</svg>

