import { useEffect } from "react";
import "../styles/partials/pages/_retningslinjer.scss";

export default function Retningslinjer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="content">
      {/* <h1>Retningslinjer</h1> */}
      <div className="guidelines-section">
        <h1>Retningslinjer for bruk av Housemaids-plattformen</h1>
        <h2>For Renholdere</h2>
        <ul>
          <li>
            Registrering: For å bli en del av Housemaids-teamet som renholder,
            må du registrere deg med et gyldig organisasjonsnummer fra
            Brønnøysundregistrene.
          </li>
          <li>
            Kvalifikasjoner: Erfaring fra renholdsbransjen er foretrukket.
            Fagbrev er en bonus, men ikke et krav. Det viktigste er at du er
            lidenskaplig opptatt av et skinnende rent hjem.
          </li>
          <li>
            Arbeidstider og områder: Angi dine tilgjengelige arbeidstider, og
            velg de områdene du er villig til å jobbe i.
          </li>
          <li>
            Rengjøringsoppgaver: Utfør rengjøringstjenester som avtalt med
            kundene. Timespris skal legges inn i plattformen og det vil bli
            gjennomført en estimert timespris på hver rengjøringstype.
          </li>
          <li>
            Betaling: Betaling skjer gjennom Stripe. Plattformen tar 15 %
            provisjon for å kunne betale for Stripe og for å kunde drifte denne
            nettsiden. Resten overføres til deg.
          </li>
          <li>
            Tilbakemeldinger: Kunder kan gi tilbakemeldinger og vurderinger fpr
            å kunne videreformidle hvor god og trygg du er som en renholder.
            Dette er inntrykk som er viktige for potensielle kunder å få. Vi
            ønsker at du som renholder skal få så høy score som mulig. Dersom du
            har veldig lav score over gjentatte vurderinger, vil det bli innført
            en samtale for å kunne finne ut hva du kan gjøre for å forbedre din
            score.
          </li>
          <li>
            Kansellering og endringer: Kunder kan kansellere oppdrag uten
            kostnad innen 48 timer. De kan endre timer kostnadsfritt. Du vil
            motta eposter ved bestilling, kansellering og endringer av timer.
          </li>
          <li>
            Regler: Hold kommunikasjon med kunder innenfor plattformen. Utfør
            oppdragene gjennom plattformen og oppfordre gjentatte bestillinger
            gjennom plattformen. Dersom det oppdages at oppdrag blir bestilt
            utenfor plattformen, vil du ikke kunne være en del av plattformen
            lengre.
          </li>
        </ul>

        <h2>For Kunder</h2>
        <ul>
          <li>
            Bestilling: Logg inn for å bestille rengjøringstjenester. Velg
            lokasjon og renholder basert på din lokasjon.
          </li>
          <li>
            Informasjon: Oppgi rengjøringstype, dato, tid og
            betalingsinformasjon for bestillingen.
          </li>
          <li>
            Renholdervalg: Velg en passende renholder basert på vurderinger og
            kommunikasjon med renholderen. Det vil også stå om renholderen er
            allergisk mot katt eller hund.
          </li>
          <li>
            Prisberegning: Prisen beregnes basert på renholderens timespris og
            arbeidsoppgavene. Alt er inkludert mva i prisen. Det er ingen ekstra
            kostnader for deg som kunde.
          </li>
          <li>
            Instruksjoner: Du kan gi instruksjoner til renholderen, men forvent
            at renholderen har nødvendig utstyr med seg.
          </li>
          <li>
            Sikkerhet og personvern: Vi tar sikkerhet og personvern på alvor.
            Informasjon lagres trygt og betalingsinformasjon håndteres gjennom
            Stripe.
          </li>
          <li>
            Kansellering og endringer: Kanseller innen 24 timer for refusjon.
            Etter det er det ikke mulig å avbestille time kostnadsfritt. Det er
            derimot kostnadsfritt å endre timene. Dette gir renholderen
            muligheten til å finne annet oppdrag innen god tid, men fremdeles ha
            en økonomisk sikkerhet.
          </li>
          <li>
            Tilbakemeldinger: Gi vurderinger etter utført jobb. Dette bidrar til
            å opprettholde høy kvalitet på tjenestene. Du kan gi vurdering i
            form av tall-karakter (1-6) og en beskrivelse på vurderingen.
          </li>
        </ul>
      </div>
    </div>
  );
}
