import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";

import * as yup from "yup";
import { useState, useContext, useEffect } from "react";

export default function Kontakt() {
  const [formError, setFormError] = useState("");
  const [textareaValue, setTextareaValue] = useState("");


  useEffect(() => {
    window.scrollTo(0, 0);
  })

  const schema = yup.object().shape({
    name: yup.string().required("Vennligst skriv inn navnet ditt"),
    email: yup.string().email().required("Vennligst skriv inn eposten din"),
    message: yup
      .string()
      .min(10, "Du må skrive en beskjed på minimum 10 tegn")
      .max(1000, "Du må skrive en beskjed på maksimum 1000 tegn")
      .required("Vennligst skriv inn beskjeden din"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  async function onSubmit(data) {
    try {
      axios.post(
        process.env.REACT_APP_TEST_URL + "/api/post/sendContactForm",
        { name: `${data.name}`, email: data.email, message: data.message }
      ).then((result) => {
        if(result.status == 200){
          document.getElementById("formValidation").innerHTML = "Innsending av skjema vellykket";
          setTimeout(() => {
            window.location.reload()
          }, 6000);
        }
      });
    } catch (error) {}
  }

  return (
    <div>
      <h1>Kontakt</h1>

      <form id="contact__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-item">
          <label htmlFor="name">Navn</label>
          <input {...register("name")} type="text" />
          {errors.name && (
            <span className="form__error logginn-form__error">
              {errors.name.message}
            </span>
          )}
          <br />
          <label htmlFor="email">Epost</label>
          <input {...register("email")} type="email" />
          {errors.email && (
            <span className="form__error logginn-form__error">
              {errors.email.message}
            </span>
          )}

          {formError && (
            <span className="form__error logginn-form__error">{formError}</span>
          )}
          <br />
          <label htmlFor="message">Beskjed</label>
          <textarea
            id="textareaField"
            name="textareaField"
            rows="10"
            {...register("message")}
          />
          {errors.message && (
            <span className="form__error logginn-form__error">
              {errors.message.message}
            </span>
          )}

          {formError && (
            <span className="form__error logginn-form__error">{formError}</span>
          )}
        </div>
        <div className="button-container">
          <button>Send melding</button>
        </div>
      <div id="formValidation"></div>
      </form>

    </div>
  );
}
