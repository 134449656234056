import rating from '../../functions/rating.js';

export default function FeaturedCard({img, name, place, stars}) {
  let icon = rating(stars)[0];
  let aria = rating(stars)[1];
  return(
    <div onClick={()=>{
      }} className='featured-card'> 
      <img src={img} alt={name} />
      <div className='featured__details'>
        <div className='featured__rating' aria-label={aria}>{icon}</div>
        <div className='featured__name'>{name}</div>
        <div className='featured__placeName'>{place}</div>
      </div>
    </div>
  )
}
