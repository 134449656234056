import Hero from "../components/landing/Hero";
import Intro from "../components/landing/Intro";
import Oppskrift from "../components/landing/Oppskrift";

export default function Landing() {
  return(
    <div className="landing-page">
      <Hero />
      <Intro />
      <Oppskrift />
    </div>
  )
}
