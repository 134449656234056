import Bookings from "../components/global/Bookings"
import Messages from "../components/global/Messages"


export default function Dashboard() {

  return(
    <div>
      <h1>Renholder dashbord</h1>
      <h2>Bookinger</h2>
      <Bookings />
      <h2>Meldinger</h2>
      <Messages />
    </div>
  )
}
