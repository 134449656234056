import axios from "axios";
import KundeProfil from "../components/profile/KundeProfil";
import RenholderProfil from "../components/profile/RenholderProfil";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function Profil() {
  const [role, setRole] = useState();
  const [userId, setUserId] = useState();
  const [user, setUser] = useState({});
  const [area2, setArea] = useState("");
  const [fetched, setFetched] = useState(false);
  let areaArray = [];

  useEffect(() => {
    checkCookie();
  }, []);


useEffect(() => {
  
}, [area2])
  useEffect(() => {
    getProfileInfo();
  // }, []);
  }, [fetched, role, userId]);

  async function getProfileInfo() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_TEST_URL + "/api/get/fetch-user",
        { params: { userId, role } }
      );
      
      if (response.status === 200) {
        setUser(response.data[0]);
        setFetched(true);
      }

      const response2 = await axios.get(
        process.env.REACT_APP_TEST_URL + "/api/get/fetch-area",
        { params: { userId, role } }
      );
      if (response2.status === 200) {
        response2.data.forEach((element) => {
          areaArray.push(element.cityName);
        });
        setArea({ areaArray });
        setFetched(true);
      }
    } catch (err) {
      console.log("Error" + err);
    }
  }

  async function checkCookie() {
    let response = await axios.get(
      process.env.REACT_APP_TEST_URL + "/api/get/readCookie",
      { withCredentials: true }
    );
    
    if (response.data.role !== undefined) {
      setRole(response.data.role);
    }

    if (response.data.id !== undefined) {
      setUserId(response.data.id);
    }

    if(response.data.jwt === undefined) {
      window.location.replace("/");
    }
  }

  if (role === "customer") {
    return (
      <div className="profile-page">
        <KundeProfil profiledata={user} />
      </div>
    );
  }
  if (role === "cleaner") {
    return (
      <div className="profile-page">
        <RenholderProfil profiledata={user} area2={area2} />
      </div>
    );
  }
  if (role === "admin") {
    return (
      <div className="profile-page">
        <h1>Welcome, Admin</h1>
      </div>
    );
  }
}
