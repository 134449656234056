import { useEffect, useState } from "react";
import bloggImage from "../images/desktop-hero-image.jpg"
import axios from "axios";
import parse from 'html-react-parser';
import Headx from 'headx';

export default function Ressurs() {
  const [blogg, setBlogg] = useState();
  const [isLoaded, setIsLoaded] = useState(false);


  useEffect(() => {
    getBlogg()
  

  }, [])

  async function getBlogg() {
    const url = window.location.pathname;
    const lastSegment = url.split('/').pop();
    axios.post(process.env.REACT_APP_TEST_URL + "/api/get/getBlogg", {bloggId: lastSegment}).then((result) => {
      if(result.data.length > 0) {
        setBlogg(result.data)
        setIsLoaded(true)

      }
    })
  }


  
  return (
    <div>

 <div>
{isLoaded  ? 
        blogg.map((blog) => {
          
          return (
            
            <div className="article" id={blog.id}>
                  <Headx
      title={blog.metatitle}
      meta={[{ name: 'description', content: `${blog.metadesc}` }]}
    />
            <img src={bloggImage}></img>
            <h1>{blog.metatitle}</h1>
            <div>{parse(blog.bloggtext)}</div>
          </div>
    
          )
        })
        : <div>Ingen artikler dessverre</div>}
      </div> 
    </div>
  );
}
