import FeaturedCard from "../components/global/FeaturedCard";
import ProfileCard from "../components/global/ProfileCard";
import Placeholder1 from '../images/profile-placeholder-1.jpg'
import Placeholder2 from '../images/profile-placeholder-2.jpg'
import Placeholder3 from '../images/profile-placeholder-3.jpg'
import Placeholder4 from '../images/profile-placeholder-4.jpg'
import {searchIcon} from '../icons/search';
import axios from "axios";
import React, { useState, useEffect } from 'react';

export default function FinnRenholder() {
  const [cleaners, setCleaners] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [currentDate, setCurrentDate] = useState('');


  useEffect(() => {
    checkCookie();
  }, []);

  async function checkCookie() {
    let response = await axios.get(
      process.env.REACT_APP_TEST_URL + "/api/get/readCookie",
      { withCredentials: true }
    );
  
    if(response.data.jwt === undefined) {
      window.location.replace("/");
    }
  }

  function showResults(e){
    let searchCriterion = e.target.value;
    fetchCleaners(searchCriterion)
  } 

  function generatePreSignedUrl(userProfilePhoto) {
    if(userProfilePhoto.length > 0) {
      return userProfilePhoto
    } else {
      return "https://www.cmcaindia.org/wp-content/uploads/2015/11/default-profile-picture-gmail-2.png"
    }
  }

  async function fetchAvailableTime() {

    //need to loop through all the cleaners thats showing
    //TODO: need to show the first date available by first looping through everything and adding the total hours together, and then loop through the final array?


    const response = await axios.get(
      process.env.REACT_APP_TEST_URL+'/api/get/fetch-bookings-cleaner', {params: {userId: 243}}).then((result) => {

        let bookingsArray = result.data;
        bookingsArray.reverse();

        for (let i = 0; i < bookingsArray.length; i++) {
          let targetDate =  new Date(bookingsArray[i].date);
          let currentDate = new Date();

          if(targetDate > currentDate) {
            let time = bookingsArray[i].time.slice(0,2);
            time = parseInt(time);
            if((time + parseInt(bookingsArray[i].estimatedTime)) < 16) { 
              targetDate = targetDate.toLocaleDateString("nb-NO", {weekday: 'long'})
              setCurrentDate(targetDate)

              break;

            }
          }
          }
        // });
      })
  }


  async function fetchCleaners(searchCriterion) {



    try {
      setFeedback('');
      const response = await axios.get(
        process.env.REACT_APP_TEST_URL+'/api/get/fetch-cleaners-area',
        { params: { searchCriterion } },
      )
      if(response.status === 200){
        setCleaners(response.data);


        fetchAvailableTime();
        if(!response.data.length){
          setFeedback('Vi har dessverre ingen renholdere i ditt område ennå');
        }
      }
      
    } catch (error) {
      console.log(error)
    } finally {
    }
  }
  return(
    <div className="finnrenholder-page">
      <h1>Finn en renholder</h1>
      <h2>Velg mellom ulike renholdere der du bor</h2>
      <div className="filter-container">
        <label>{searchIcon}</label>
        <input onChange={showResults} type="text" id="finn-renholder" placeholder="Søk der du bor" />
      </div>
      <div className="results-container">
        {cleaners.map((cleaner)=>{
           const imageUrl = generatePreSignedUrl(cleaner.profilephoto); 
          return(
            <ProfileCard key={cleaner.iduser} id={cleaner.iduser} img={imageUrl} name={cleaner.firstName} allergihund={cleaner.allergihund} allergikatt={cleaner.allergikatt} nextAppointment={currentDate} price={cleaner.hourlyPay} stars={cleaner.currentRating} />
          )
        })}
        {feedback && <span>{feedback}</span>}
      </div>
      <h2>Supre renholdere med fornøyde kunder</h2>
      <div className="featured-container">
        <FeaturedCard img={Placeholder1} nextAppointment='Torsdag' name='Aleksander' stars={4.75} />
        <FeaturedCard img={Placeholder2} nextAppointment='Onsdag' name='Justina' stars={4.3} />
        <FeaturedCard img={Placeholder3} nextAppointment='Torsdag' name='Luca' stars={4.1} />
        <FeaturedCard img={Placeholder4} nextAppointment='Fredag' name='Nineh' stars={4.9} />
      </div>
    </div>
  )
}
