import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
export default function AdminDashboard() {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    checkCookie();
  });

  async function checkCookie() {
    // let response = await axios.get(
    //   process.env.REACT_APP_TEST_URL + "/api/get/readCookie",
    //   { withCredentials: true }
    // );
    // if (response.data.jwt !== undefined) {

      let isAdminQuery = await axios.get(
        process.env.REACT_APP_TEST_URL + "/isAdmin",
        { withCredentials: true }
      );


      if(isAdminQuery.data == "hello") {
        window.location.replace("/");
      } else {
        setIsAdmin(isAdminQuery.data);

      }

    }

  if (isAdmin) {
    return (
      <div className="adminDashboard">
        <h1>Admin Dashboard</h1>
        <div className="flexContainerAdmin">
          <Link to="renholderOverview">
            <div className="flexCardList">Renholdere</div>
          </Link>

          <Link to="bookingOverview">
            {" "}
            <div className="flexCardList">Bookinger</div>
          </Link>

          <Link to="kundeOverview">
            {" "}
            <div className="flexCardList">Kunder</div>
          </Link>

          <Link to="/lagArtikkel">
            {" "}
            <div className="flexCardList">Lag artikkel</div>
          </Link>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
