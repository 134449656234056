import { useEffect } from "react";

export default function Faq() {


  useEffect(() => {
    window.scrollTo(0, 0);
  })
  return (
    <div className="content">
      {/* <h1>Hvordan fungerer det?</h1> */}
      {/* <div id="content"> */}
      <div className="how-it-works-section">
        <h1>Hvordan fungerer det for renholdere på Housemaids-plattformen?</h1>

        <h2>Registrering som renholder på Housemaids-plattformen</h2>
        <p>
          For å registrere deg som renholder på Housemaids, følg disse trinnene:
        </p>
        <ol>
          <li>Trykk på "Kom i gang" og deretter "Registrer bruker".</li>
          <li>Velg "Renholder" som din brukertype.</li>
          <li>
            Du må ha et organisasjonsnummer fra Brønnøysundregistrene for å
            kunne fullføre registreringen som renholder.
          </li>
        </ol>

        <h2>Kvalifikasjoner og erfaring</h2>
        <p>
          Du trenger kun erfaring fra renholdsbransjen for å bli en del av
          Housemaids-teamet. Mens det er fint om du har fagbrev, er det ikke et
          absolutt krav.
        </p>

        <h2>Valg av arbeidstider og områder</h2>
        <p>
          Initialt antas det at du kan jobbe mellom 8:00 og 16:00. Etter hvert
          vil du kunne legge inn de tidspunktene du er tilgjengelig for jobb.
        </p>

        <h2>Rengjøringsoppgaver gjennom Housemaids</h2>
        <p>
          På Housemaids-plattformen vil du hovedsakelig utføre husvask. Kunden
          kan velge mellom ulike typer vaskeoppgaver, utvask og vårrengjøring.
          Estimert tid for hver jobb er angitt, og du vil legge inn din
          timespris.
        </p>

        <h2>Betalingssystem og utbetaling</h2>
        <p>
          Betaling skjer gjennom Stripe. Du vil motta betaling på din konto
          innen 5 dager etter at jobben er fullført. Housemaids tar en 10 %
          provisjon fra totalsummen før resten overføres til deg. Dette går til
          å dekke kostnader for Stripe, for videre utvikling og drift av
          nettsiden.
        </p>

        <h2>Kunde tilbakemeldinger</h2>
        <p>
          Kunder har mulighet til å gi tilbakemeldinger og vurderinger etter at
          jobben er utført. Dette vil påvirke din profil som renholder på
          plattformen.
        </p>

        <h2>Kanselleringer og endringer</h2>
        <p>
          Kunder kan avbestille oppdraget uten kostnad innen 48 timer før avtalt
          tid. Du vil bli varslet via e-post om eventuelle endringer i
          timeplanen.
        </p>

        <h2>Retningslinjer og regler</h2>
        <p>
          Det er viktig å følge retningslinjene på Housemaids-plattformen.
          Kontakt med kunder bør kun skje gjennom plattformen, og oppdrag bør
          utføres innenfor plattformen. Kunden forventes å fortsette å betale
          for oppdrag gjennom plattformen, og gjentatte bestillinger skal
          henvises til plattformen.
        </p>
      </div>

      <div className="how-it-works-section">
        <h1>
          Hvordan bestille rengjøringstjenester gjennom Housemaids-plattformen?
        </h1>

        <h2>Logg inn og velg en renholder</h2>
        <ul>
          <li>Du må logge inn for å kunne bestille rengjøringstjenester.</li>
          <li>
            Velg din ønskede lokasjon og finn en renholder som passer til din
            lokasjon.
          </li>
        </ul>

        <h2>Oppgi nødvendig informasjon</h2>
        <ul>
          <li>
            Vi vil spørre deg om hvilken type rengjøring du ønsker, dato,
            tidspunkt og om det skal være en gjentagende rengjøring.
          </li>
          <li>
            Legg inn nødvendig betalingsinformasjon, og du vil motta en
            kvittering på e-post.
          </li>
        </ul>

        <h2>Velg en passende renholder</h2>
        <p>For å velge en renholder som passer for deg:</p>
        <ul>
          <li>
            Sjekk vurderingene til renholderen for å vurdere kvaliteten på
            tjenestene.
          </li>
          <li>
            Send meldinger til renholderen på forhånd for å avklare behov og
            preferanser.
          </li>
        </ul>

        <h2>Prisberegning for tjenesten</h2>
        <p>Prisen for rengjøringstjenesten:</p>
        <ul>
          <li>Ingen ekstra kostnader for deg som kunde.</li>
          <li>
            Renholderne oppgir sine timesatser, og kostnaden beregnes ut fra
            arbeidsoppgavene og eventuell kjøretid.
          </li>
        </ul>

        <h2>Spesielle instruksjoner til renholderen</h2>
        <p>Du kan gi spesielle instruksjoner til renholderen:</p>
        <ul>
          <li>
            Renholderne har vanligvis med seg nødvendig utstyr, men du kan
            informere om spesifikke behov.
          </li>
        </ul>

        <h2>Sikkerhet og personvern</h2>
        <p>Sikkerhet og personvern håndteres nøye:</p>
        <ul>
          <li>All informasjon lagres sikkert i en database.</li>
          <li>Passord er kryptert for økt sikkerhet.</li>
          <li>
            Betalingsinformasjon lagres trygt i Stripe for ekstra beskyttelse og
            håndteres ikke av plattformen selv.
          </li>
        </ul>

        <h2>Kansellering eller endring av rengjøring</h2>
        <p>Hvis du ønsker å kansellere eller endre en planlagt rengjøring:</p>
        <ul>
          <li>Kanseller innen 48 timer for å få pengene refundert.</li>
          <li>
            Dette sikrer renholderen muligheten til å finne en annen jobb i
            stedet.
          </li>
        </ul>

        <h2>Tilbakemeldinger og vurderinger</h2>
        <p>Gi tilbakemeldinger og vurderinger for renholderens tjenester:</p>
        <ul>
          <li>
            Etter at jobben er fullført, kan du gi vurderinger inne på
            plattformen.
          </li>
          <li>Dette bidrar til å opprettholde høy kvalitet på tjenestene.</li>
        </ul>
      </div>
    </div>

    // </div>
  );
}
