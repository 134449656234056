// Form for profiles to edit details.
import axios from "axios";
import { useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";

export default function MiniForm({
  dataName,
  label,
  textarea,
  defaultInput,
  placeholder,
  refresh,
}) {
  const [userId, setUserId] = useState();
  const [role, setRole] = useState();
  const [email, setEmail] = useState();
  const [checkingOrgNummer, setCheckingOrgNummer] = useState(false);
  const [confirmedOrgNummer, setConfirmedOrgNummer] = useState("");
  const [rejectedOrgNummer, setRejectedOrgNummer] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [poststed, setPostSted] = useState("");
  const [emailFeedback, setEmailFeedback] = useState("");
  const [timesprisFeedback, setTimesprisFeedback] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  let cookies = new Cookies();

  useEffect(() => {
    checkCookie();
  }, []);

  async function checkCookie() {
    let response = await axios.get(
      process.env.REACT_APP_TEST_URL + "/api/get/readCookie",
      { withCredentials: true }
    );
    if (response.data.role !== undefined) {
      setRole(response.data.role);
    }

    if (response.data.id !== undefined) {
      setUserId(response.data.id);
    }
    if (response.data.email !== undefined) {
      setEmail(response.data.email);
    }
  }

  function debounce(func, delay) {
    let timerId;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  }

  const debouncedSubmit = debounce((data) => onSubmit(data), 500);

  async function onSubmit(data) {

    let response;
    switch (data.target.name) {
      case "beskrivelse":
        response = await axios.post(
          process.env.REACT_APP_TEST_URL + "/api/post/oppdatere-renholder",
          {
            data: {
              beskrivelse: data.target.value,
              id: userId,
            },
          }
        );
        break;
      case "timespris":
        response = await axios.post(
          process.env.REACT_APP_TEST_URL + "/api/post/oppdatere-renholder",
          {
            data: {
              timespris: data.target.value,
              id: userId,
            },
          }
        );
        break;
      case "startTime":
        response = await axios.post(
          process.env.REACT_APP_TEST_URL + "/api/post/oppdatere-renholder",
          {
            data: {
              startTime: data.target.value,
              id: userId,
            },
          }
        );
        break;
      case "endTime":
        response = await axios.post(
          process.env.REACT_APP_TEST_URL + "/api/post/oppdatere-renholder",
          {
            data: {
              endTime: data.target.value,
              id: userId,
            },
          }
        );
        break;
      case "kontonummer":
        response = await axios.post(
          process.env.REACT_APP_TEST_URL + "/api/post/oppdatere-renholder",
          {
            data: {
              kontonummer: data.target.value,
              id: userId,
            },
          }
        );
        break;
      case "adresseGate":
        response = await axios.post(
          process.env.REACT_APP_TEST_URL + "/api/post/oppdatere-bruker",
          {
            data: {
              id: userId,
              adresse: data.target.value,
            },
          }
        );

        break;
      case "fornavn":
        response = await axios.post(
          process.env.REACT_APP_TEST_URL + "/api/post/oppdatere-bruker",
          {
            data: {
              id: userId,
              // email: email,
              fornavn: data.target.value,
            },
          }
        );
      case "etternavn":
        response = await axios.post(
          process.env.REACT_APP_TEST_URL + "/api/post/oppdatere-bruker",
          {
            data: {
              id: userId,
              // email: email,
              etternavn: data.target.value,
            },
          }
        );
        break;
      case "telefon":
        response = await axios.post(
          process.env.REACT_APP_TEST_URL + "/api/post/oppdatere-bruker",
          {
            data: {
              id: userId,
              // email: email,
              telefon: data.target.value,
            },
          }
        );
        break;
      case "epost":
        response = await axios.post(
          process.env.REACT_APP_TEST_URL + "/api/post/oppdatere-bruker",
          {
            data: {
              id: userId,
              // email: email,
              epost: data.target.value,
            },
          }
        );
        break;
      default:
        console.log("The key pressed is not A, B, C, a, b, or c");
    }
/*
    // data.email = email; //this is wrong
    // data.id = userId;
    // data.role = role;

    // if (poststed && data.length >= 2) {
    //   data.by = poststed;
    // }

    // if (data.epost && data.length >= 10) {
    //   const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    //   if (data.epost.match(regex)) {
    //     cookies.set("email", data.epost);
    //     // setSubmitDisabled(false);
    //     setEmailFeedback("");
    //   } else {
    //     setEmailFeedback("Vennligst gi en gyldig epostadresse");
    //     return;
    //     // setSubmitDisabled(true);
    //   }
    // }

    // if (data.role === "cleaner") {
    //   try {
    //     if (
    //       (data.timespris !== undefined && data.timespris > 180 * 1.25) ||
    //       data.omrader !== undefined ||
    //       data.startTime !== undefined ||
    //       data.endTime !== undefined ||
    //       data.beskrivelse !== undefined ||
    //       data.orgnummer !== undefined ||
    //       data.kontonummer !== undefined
    //     ) {
    //       const response = await axios.post(
    //         process.env.REACT_APP_TEST_URL + "/api/post/oppdatere-renholder",
    //         { data }
    //       );
    //       if (response.status === 200) {
    //         window.location.reload();
    //       }
    //     }
    //     if (
    //       data.adresseGate !== undefined ||
    //       data.postnummer !== undefined ||
    //       data.epost !== undefined ||
    //       data.telefon !== undefined ||
    //       data.fornavn !== undefined ||
    //       data.etternavn !== undefined
    //     ) {
    //       const response2 = await axios.post(
    //         process.env.REACT_APP_TEST_URL + "/api/post/oppdatere-bruker",
    //         { data }
    //       );
    //       if (response2.status === 200) {
    //         window.location.reload();
    //       }
    //     }
    //   } catch (error) {
    //     console.log(
    //       "There was an error trying to create userprofile, with error: ",
    //       error
    //     );
    //   }
    // } else {
    //   try {
    //     const response = await axios.post(
    //       process.env.REACT_APP_TEST_URL + "/api/post/oppdatere-bruker",
    //       { data }
    //     );
    //     if (response.status === 200) {
    //       window.location.reload();
    //     }
    //   } catch (error) {
    //     console.log(
    //       "There was an error trying to create userprofile, with error: ",
    //       error
    //     );
    //   }
    // }
    */
  }
  function closeEdit(event) {
    event.currentTarget.parentElement.parentElement.classList.toggle(
      "show-form"
    );
  }

  function inputChange(e) {
    console.log(e);
    if (e.target.name === "orgnummer") {
      checkReg(e);
    }
    if (e.target.name === "epost") {
      const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (e.target.value.match(regex)) {
        setEmailFeedback("");
      }
    }
    if (e.target.name === "postnummer") {
      checkGeo(e);
    }
  }

  async function checkGeo(e) {
    const inputNumber = parseInt(e.target.value.split(" ").join(""));
    if (inputNumber.toString().length === 4 && !isNaN(inputNumber)) {
      try {
        const response = await axios.get(
          "https://ws.geonorge.no/adresser/v1/sok?treffPerSide=1&postnummer=" +
            inputNumber
        );
        if (response.data.adresser[0]) {
          setPostSted(response.data.adresser[0].poststed);
          setSubmitDisabled(false);
        } else {
          setPostSted("Ugyldig postnummer");
          setSubmitDisabled(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  // Function to check org number (same as in Nyrenholder page form).
  async function checkReg(e) {
    setRejectedOrgNummer("");
    setConfirmedOrgNummer("");
    const inputNumber = parseInt(e.target.value.split(" ").join(""));
    if (inputNumber.toString().length === 9 && !isNaN(inputNumber)) {
      setCheckingOrgNummer(true);
      errors.orgnummer = "";
      try {
        const response = await axios.get(
          "https://data.brreg.no/enhetsregisteret/api/enheter/" + inputNumber
        );
        if (
          response.status === 200 &&
          !response.data.konkurs &&
          !response.data.underTvangsavviklingEllerTvangsopplosning
        ) {
          setCheckingOrgNummer(false);
          setSubmitDisabled(false);
          setRejectedOrgNummer("");
          setConfirmedOrgNummer(
            `${response.data.navn} godkjent med Brønnøysundregistrene`
          );
        }
        if (
          response.data.konkurs ||
          response.data.underTvangsavviklingEllerTvangsopplosning
        ) {
          setCheckingOrgNummer(false);
          setSubmitDisabled(true);
          setRejectedOrgNummer(
            `${response.data.navn} er konkurs eller under tvangsavvikling eller tvangsoppløsning`
          );
        }
      } catch (error) {
        if (error.response.status === 404) {
          setCheckingOrgNummer(false);
          setRejectedOrgNummer("Vennligst oppgi et gyldig organisasjonsnummer");
          setSubmitDisabled(true);
        } else {
          setRejectedOrgNummer(
            "Beklager, en feil har oppstått med org nummer godkjenning. Vennligst prøv igjen."
          );
        }
      }
    } else {
      setCheckingOrgNummer(false);
      setConfirmedOrgNummer("");
      setRejectedOrgNummer("Må være 9 siffre");
    }
  }

  if (textarea) {
    return (
      <form>
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <label htmlFor={dataName}>{label}:</label>
        <textarea
          {...register(`${dataName}`)}
          defaultValue={defaultInput}
          rows="4"
          onKeyUp={(data) => {
            debouncedSubmit(data);
          }}
        />
        <div className="flex-break"></div>
        {/* <button className="under-button" onClick={handleSubmit(onSubmit)}>
          oppdater
        </button>
        <button type="button" className="close-button" onClick={closeEdit}>
          x
        </button> */}
      </form>
    );
  } else {
    return (
      <form>
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <label htmlFor={dataName}>{label}:</label>
        <input
          {...register(`${dataName}`)}
          defaultValue={defaultInput}
          placeholder={placeholder}
          onChange={(data) => {
            debouncedSubmit(data);
          }}
        />
        {/* <div className="flex-break"></div> */}
        {/* <button onClick={handleSubmit(onSubmit)} disabled={submitDisabled}>
          oppdater
        </button>
        <button type="button" className="close-button" onClick={closeEdit}>
          x
        </button> */}
        {checkingOrgNummer && <span>Sjekker...</span>}
        {/* {poststed && <div className="feedback">{poststed}</div>} */}
        {emailFeedback && <div className="feedback">{emailFeedback}</div>}
        {timesprisFeedback && (
          <div className="feedback">{timesprisFeedback}</div>
        )}
        {confirmedOrgNummer && (
          <span
            className="feedback"
            style={{ height: 35, marginTop: 5, display: "inline-block" }}
          >
            {confirmedOrgNummer}
          </span>
        )}
        {rejectedOrgNummer && (
          <span className="feedback" style={{ color: "firebrick" }}>
            {rejectedOrgNummer}
          </span>
        )}
      </form>
    );
  }
}
