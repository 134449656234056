import Placeholder1 from '../../images/profile-placeholder-1.jpg';
import Placeholder2 from '../../images/profile-placeholder-2.jpg';
import Placeholder3 from '../../images/profile-placeholder-3.jpg';
import Placeholder4 from '../../images/profile-placeholder-4.jpg';

import FeaturedCard from '../global/FeaturedCard';

export default function Intro() {
  return(
    <div className="intro">
      <h2>Velg mellom ulike renholdere der du bor</h2>
      <div className='featured-container'>
        <FeaturedCard img={Placeholder1} place='Oslo' />
        <FeaturedCard img={Placeholder2} place='Trondheim' />
        <FeaturedCard img={Placeholder3} place='Bergen' />
        <FeaturedCard img={Placeholder4} place='Bodø' />
      </div>
    </div>
  )
}
