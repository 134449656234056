import { useEffect } from "react";

export default function Om() {

  useEffect(() => {
    window.scrollTo(0, 0);
  })
  return (
    <div className="content">
      {/* <h1>Om oss</h1> */}
      <div className="about-us-section">
        <h1>Om Housemaids</h1>
        <p>
          Velkommen til Housemaids - din pålitelige plattform for
          rengjøringstjenester!
        </p>

        <p>
          Vi er et dedikert team som har skapt Housemaids med mål om å tilby
          enkel tilgang til kvalitetsrengjøring for både renholdere og kunder.
          Med vår brukervennlige plattform kan du enkelt koble renholdere med de
          som trenger rengjøringstjenester, og skape en positiv opplevelse for
          alle involverte.
        </p>

        <p>
          For renholdere gir vi muligheten til å registrere seg og tilby deres
          tjenester til potensielle kunder. Vårt mål er å hjelpe renholdere med
          å finne jobber som passer deres tidsplan og ferdigheter, samtidig som
          de kan tjene en rettferdig kompensasjon for sitt arbeid.
        </p>

        <p>
          For kunder gir vi en enkel og praktisk måte å bestille profesjonelle
          rengjøringstjenester. Gjennom vår plattform kan du velge en erfaren
          renholder basert på dine behov og preferanser, og trygt bestille
          tjenester med fokus på kvalitet og pålitelighet.
        </p>

        <p>
          Hos Housemaids setter vi sikkerhet og personvern i høysetet. All
          informasjon lagres sikkert i våre databaser, og betalingsinformasjon
          håndteres gjennom sikre tjenester som Stripe.
        </p>

        <p>
          Vi jobber kontinuerlig med å forbedre og utvide plattformen for å møte
          behovene til våre brukere. Vi er stolte av å kunne tilby en tjeneste
          som bringer renholdere og kunder sammen på en effektiv og pålitelig
          måte.
        </p>

        <p>
          Vi ser frem til å være din partner for rengjøringstjenester. Ta gjerne
          kontakt med oss hvis du har spørsmål eller tilbakemeldinger!
        </p>
      </div>
    </div>
  );
}
