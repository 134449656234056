import './styles/style.scss';
import { AuthProvider } from './context/AuthContext';
import {Routes, Route} from "react-router-dom";
import Landing from './pages/Landing';
import Logginn from './pages/Logginn';
import NyKunde from './pages/NyKunde';
import NyBruker from './pages/Nybruker';
import NyRenholder from './pages/NyRenholder';
import KundeMessages from './pages/KundeMessages';
import KundeBookings from './pages/KundeBookings';
import FinnRenholder from './pages/FinnRenholder';
import Retningslinjer from './pages/Retningslinjer';
import Renholder from './pages/Renholder';
import Profil from './pages/Profil';
import Faq from './pages/Faq';
import Kontakt from './pages/Kontakt';
import Om from './pages/Om';
import Ressurser from './pages/Ressurser';
import Ressurs from './pages/Ressurs';
import Navbar from './components/global/Navbar';
import Footer from './components/global/Footer';
import Dashboard from './pages/Dashboard';
import GlemtPassord from './pages/GlemtPassord';
import NyttPassord from './pages/NyttPassord';
import NotFound from './pages/NotFound';
import CreateArticle from './components/blogg/createArticle';
import AdminDashboard from './pages/Admin/AdminDashboard';
import KundeOverview from './pages/Admin/KundeOverview';
import RenholderOverview from './pages/Admin/RenholderOverview';
import BookingOverview from './pages/Admin/BookingOverview';


function App() {
  return (
    <AuthProvider>
      <div className='wrapper'>
        <Navbar />
        <Routes>
          <Route path="/" exact element={ <Landing/>} />
          <Route path="logginn" element={ <Logginn/>} />
          <Route path="nybruker" element={ <NyBruker/>} />
          <Route path="nykunde" element={ <NyKunde/>} />
          <Route path="nyrenholder" element={ <NyRenholder/>} />
          <Route path="finnrenholder" element={ <FinnRenholder/>} />
          <Route path="ressurser" element={ <Ressurser/>} />
          <Route path="ressurser/:id" element={ <Ressurs/>} />
          <Route path="retningslinjer" element={ <Retningslinjer/>} />
          <Route path="profil" element={ <Profil/>} />
          <Route path="renholder/:id" element={ <Renholder/>} />
          <Route path="dashboard" element={ <Dashboard/>} />
          <Route path="messages" element={ <KundeMessages/>} />
          <Route path="bookings" element={ <KundeBookings/>} />
          <Route path="kontakt" element={ <Kontakt/>} />
          <Route path="faq" element={ <Faq/>} />
          <Route path="om" element={ <Om/>} />
          <Route path="glemtPassord" element={ <GlemtPassord/>} />
          <Route path="nyttPassord" element={ <NyttPassord/>} />
          <Route path="lagArtikkel" element={ <CreateArticle/>} />
          <Route path="adminDashboard" element={ <AdminDashboard/>} />
          <Route path="/adminDashboard/kundeOverview" element={<KundeOverview />} />
          <Route path="/adminDashboard/renholderOverview" element={<RenholderOverview />} />
          <Route path="/adminDashboard/bookingOverview" element={<BookingOverview />} />
      
          {/* If no other path exists */}
          <Route path="*" element={ <NotFound/>} />
        </Routes>
      </div>
      <Footer />
    </AuthProvider>
  );
}

export default App;
