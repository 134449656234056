import { Link } from "react-router-dom"

export default function Hero() {
  return(
    <div className="hero">
      <div className="hero__content">
        <h1 className="hero__caption">Finn en renholder som passer for deg!</h1>
        <div className="link-container">
          <Link to='nybruker' className='cta landing-cta'>Opprett Bruker</Link>
        </div>
      </div>
    </div>
  )
}
