import { Link } from "react-router-dom"


export default function Oppskrift() {
  return(
    <div className="oppskrift">
      <h2>6 enkle steg</h2>
      <hr />
      <ol>
        <li>Velg bosted</li>
        <li>Se hvilke renholdere som er ledig</li>
        <li>Les anmeldelser</li>
        <li>Velg renholder</li>
        <li>Velg dato og tidspunkt</li>
        <li>Avtale opprettet</li>
      </ol>
      <div className="link-container">
        <Link to='nybruker' className="cta">Kom i gang nå</Link>
      </div>
      
    </div>
    
  )
}
