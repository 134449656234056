import { useEffect } from "react";
import { useState } from "react";
import MiniForm from "../micro/MiniForm";
import AreaForm from "../micro/AreaForm";
import axios from "axios";
import Cookies from "universal-cookie";
import profilePlaceholder from "../../images/profilePlaceholder.jpg";
// import CsvDownloader from "react-csv-downloader";

export default function RenholderProfil({ profiledata, area2 }) {
  let cookies = new Cookies();
  const [profileImage, setProfileImage] = useState("");
  const [notification, setNotification] = useState("");
  const [area, setArea] = useState("");
  const [fetched, setFetched] = useState(false);
  const [fullName, setFullName] = useState("");
  const [userId, setUserId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [payouts, setPayouts] = useState();
  const [isAllergyKatt, setisAllergyKatt] = useState(false);
  const [isAllergyHund, setisAllergyHund] = useState(false);

  useEffect(() => {
    if (deleteModal) {
      window.scrollTo(0, 0);
    }
  });

  useEffect(() => {
    checkCookie();
    checkProfile();

    handleBankStatements();
  }, []);




  async function checkCookie() {
    let response = await axios.get(
      process.env.REACT_APP_TEST_URL + "/api/get/readCookie",
      { withCredentials: true }
    );

    if (response.data.id !== undefined) {
      setUserId(response.data.id);
    }
  }

  useEffect(() => {
   
    if (!area2.areaArray || (area2.areaArray && !area2.areaArray.length)) {
      setArea("OBS OBS! Ingen  områder spesifisert");
    } else {
      setArea("");
    }
  }, [area2]);

  useEffect(() => {
    {
    }
    if (
      !profiledata.description ||
      !profiledata.hourlyPay ||
      !area2.areaArray
    ) {
      setNotification(
        "Noen felt mangler, pass på at profilen er komplett og oppdatert"
      );
    } else if (area2.areaArray && !area2.areaArray.length) {
      setNotification(
        "Noen felt mangler, pass på at profilen er komplett og oppdatert"
      );
    } else {
      setNotification("");
    }

    if (profiledata.allergikatt == 1) {
      setisAllergyKatt(true);
    }
    if (profiledata.allergihund == 1) {
      setisAllergyHund(true);
    }
  }, [profiledata, area2, isAllergyHund, isAllergyKatt]);

  function handleClick(event) {
    const inputField =
      event.currentTarget.nextElementSibling.firstElementChild
        .nextElementSibling;
    event.currentTarget.parentElement.classList.toggle("show-form");
    inputField.focus();
    if (event.currentTarget.lastChild.data) {
      inputField.defaultValue = `${event.currentTarget.lastChild.data}`;
    }
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];

   
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  function prepareDownload(payout) {}

  function handleCheckBox(event) {
    let typeAllergy = event.target.id;
    let isChecked = event.target.checked;

    axios
      .post(
        process.env.REACT_APP_TEST_URL + "/api/get/changeAllergies",
        { userId: userId, typeAllergy: typeAllergy, isChecked: isChecked },
        { withCredentials: true }
      )
      .then((result) => {
        if (result.data) {
          if (typeAllergy == "hund") {
            setisAllergyHund(isChecked);
          }
          if (typeAllergy == "katt") {
            setisAllergyKatt(isChecked);
          }

          window.location.reload();
        }
      });
  }

  function handleHours(event) {
    event.target.parentElement.parentElement.classList.toggle("show-form");
  }

  function handlePicture(event) {
    // if (event.currentTarget.className === "clickable-picture") {
    document.getElementById("formImage2").style.visibility = "visible";
    // }
  }

  async function handleBankStatements() {
    //try to find out how to make it so i can send it with the userid thats generated
    await axios
      .post(
        process.env.REACT_APP_TEST_URL + "/api/post/transaksjoner",
        {},
        { withCredentials: true }
      )
      .then((result) => {
        setPayouts(result.data.data);
      });
  }

  async function deleteArea(area) {
    try {
      const response = await axios.post(
        process.env.REACT_APP_TEST_URL + "/api/post/slett-area",
        { areaName: area, userId: userId }
      );
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.log(
        "There was an error trying to delete area, with error: ",
        error
      );
    }
  }

  async function fetchProfilePicture() {
    try {
      const response = await axios.post(
        process.env.REACT_APP_TEST_URL + "/api/get/profileImage",
        {
          name: profiledata.firstName + profiledata.lastName,
          id: userId,
        },
        { withCredentials: true }
      );
      if (response.data) {
        setProfileImage(response.data[0].profilephoto);
      } else {
        setProfileImage(profilePlaceholder);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFetched(true);
    }
  }

  function checkProfile() {
    if (profiledata.profilephoto !== undefined) {
      setProfileImage(profiledata.profilephoto);
    } else {
      axios
        .post(
          process.env.REACT_APP_TEST_URL + "/api/get/profileImage",
          { userId: userId },
          { withCredentials: true }
        )
        .then((result) => {
          const timestamp = new Date().getTime();
          setProfileImage(`${result.data}?${timestamp}`);
          // setProfileImage(result.data);
        });
    }
  }

  async function imageUpload(e) {
    e.preventDefault();
    let file = e.target[0].files[0];

    const formData = new FormData();
    formData.append("image", file);
    formData.append("name", profiledata.firstName + profiledata.lastName);
    try {
      const response = await axios.post(
        process.env.REACT_APP_TEST_URL + "/api/post/profileImage",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        document.getElementById("formImage2").style.visibility ="hidden";
      }
    } catch (error) {
    } finally {
      setFetched(false);
    }
  }

  async function deleteAccount() {
    axios
      .post(process.env.REACT_APP_TEST_URL + "/api/post/deleteAccount", {
        iduser: profiledata.iduser,
        idcleaner: profiledata.idcleaner,
      })
      .then((result) => {
        if (result.data) {
          axios
            .get(process.env.REACT_APP_TEST_URL + "/api/post/deleteCookie", {
              withCredentials: true,
            })
            .then((result) => {
              if (result.data) {
                window.location.replace("/");
              }
            });
        }
      });
  }

  return (
    <div className="profile-container">
      <h1 className="profile-container__name">
        {profiledata.firstName} {profiledata.lastName} - Profil
      </h1>
      <div>Org nummer:</div>
      <div>{profiledata.orgNumber}</div>
      <p>Klikk for å endre detaljene.</p>
      <div class="section1">
        <div
          class="imageSection"
          onClick={handlePicture}
          onKeyDown={""}
          tabIndex="0"
        >
          {/* <div>Image</div> */}
          <img
            className="profilephoto"
            src={profileImage}
            alt="Profile, click to change"
          />
          {/* <div className="imageBlob"></div> */}
          <form id="formImage2" onSubmit={imageUpload}>
            <label htmlFor="image">Velg bilde fil</label>
            <input type="file" id="image" name="image" onChange={handleImageChange} />
            <button>Last opp</button>
          </form>
          <div className="clickable-picture"></div>
        </div>
        <div class="profileSection">
          <MiniForm
            dataName="fornavn"
            label="Fornavn"
            defaultInput={profiledata.firstName}
          />
          <MiniForm
            dataName="etternavn"
            label="Etternavn"
            defaultInput={profiledata.lastName}
          />

          <MiniForm
            dataName="epost"
            label="Epost"
            defaultInput={profiledata.email}
          />
          <MiniForm
            dataName="telefon"
            label="Telefon"
            defaultInput={profiledata.phonenumber}
          />
        </div>
      </div>
      <div class="section2">
        <div class="formSection1">
          <MiniForm
            dataName="beskrivelse"
            label="Beskrivelse"
            textarea={true}
            defaultInput={profiledata.description}
            placeholder="Legg inn en god beskrivelse av deg"
          />
          <MiniForm
            dataName="timespris"
            label="Timespris inkl mva"
            defaultInput={profiledata.hourlyPay}
            placeholder="Skriv inn sum her ink mva"
          />{" "}
          <MiniForm
            dataName="startTime"
            label="Start-tid (7-23)"
            defaultInput={profiledata.startTime}
            placeholder="Skriv inn når du starter"
          />
          <MiniForm
            dataName="adresseGate"
            label="Adresse"
            defaultInput={profiledata.address}
          />
        </div>
        <div class="formSection2">
          <MiniForm
            dataName="kontonummer"
            label="Kontonummer"
            defaultInput={profiledata.accountNumber}
          />
          <MiniForm
            dataName="endTime"
            label="Slutt-tid (7-23)"
            defaultInput={profiledata.endTime}
            placeholder="Skriv inn når du slutter"
          />

          <MiniForm
            dataName="postnummer"
            label="Postnummer"
            defaultInput={profiledata.zipCode}
          />

          <div className="placesContainer">
            {area2.areaArray?.map((area, index) => {
              return (
                <div key={index} className="cleaner-profile-area">
                  <span className="cleaner-profile-area__label"> {area}</span>
                  <span
                    className="deleteArea"
                    onClick={(e) => {
                      deleteArea(area);
                    }}
                  >
                    x
                  </span>
                </div>
              );
            })}
            {area}
          </div>
          <AreaForm
            id="areaform"
            dataName="omrader"
            label="Legg til kommuner"
          />
        </div>
      </div>
      <div>Allergier</div>
      <div class="section3">
        <div class="formSection1">
          <label htmlFor="">Hund</label>
          <input
            type="checkbox"
            name="hund"
            id="hund"
            checked={isAllergyHund}
            onClick={(e) => {
              handleCheckBox(e);
            }}
          />
        </div>
        <div class="formSection1">
          <label htmlFor="">Katt</label>
          <input
            type="checkbox"
            name="katt"
            id="katt"
            checked={isAllergyKatt}
            onClick={(e) => {
              handleCheckBox(e);
            }}
          />
        </div>
      </div>
      <div class="section4">
        <div class="formSection1">
        </div>
        <div class="formSection2">
          <div>Slett konto</div>
        </div>
      </div>


      <div>
        <h2>Utbetalinger</h2>
        {payouts ? (
          <div className="flexRow wrap spaceBetween">
            {payouts.map((payout) => {
              return (
                <div className=" payoutsCard borderContainer" id={payout.id}>
                  {/* <CsvDownloader
                    filename={payout.id}
                    extension=".csv"
                    separator=","
                    wrapColumnChar="'"
                    datas={[payout]}
                    text="DOWNLOAD"
                  /> */}
                  {/* <div onClick={(e) => prepareDownload(payout)}>Last ned</div> */}
                  <p>Id: {payout.id}</p>
                  <p>Kr: {payout.amount / 100}</p>
                  <p>
                    Forventet på konto:{" "}
                    {
                      new Date(payout.arrival_date * 1000)
                        .toISOString()
                        .match(/^\d{4}-\d{2}-\d{2}/)[0]
                    }
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          <div></div>
        )}
      </div>

      {deleteModal ? (
        <div className="overlay">
          <div className="deleteModal">
            <p>Er du sikker på at du ønsker å slette profilen din?</p>
            <div>
              <button onClick={deleteAccount}>Ja, slett bruker</button>
              <button onClick={() => setDeleteModal(false)}>
                Nei, ikke slett meg!
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
