import axios from "axios";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "../components/micro/Button";
import Cookies from "universal-cookie";
import AuthContext from "../context/AuthContext";
// import dotenv from  'dotenv'
// import path from 'path'

var SHA256 = require("crypto-js/sha256");
var bcrypt = require("bcryptjs");
var salt = bcrypt.genSaltSync(10);

const cookies = new Cookies();

export default function NyKunde() {
  const [loggedIn, setLoggedIn] = useContext(AuthContext);
  const navigate = useNavigate();
  const [poststed, setPostSted] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const schema = yup.object().shape({
    fornavn: yup.string().required("Vennligst skriv inn navnet ditt"),
    etternavn: yup.string().required("Vennligst skriv inn etternavnet ditt"),
    epost: yup
      .string()
      .required("Vennligst oppgi eposten din")
      .email("Vennligst skriv inn en gyldig e-postadresse"),
    telefon: yup.string().required("Vennligst oppgi telefonnummeret ditt"),
    adresseGate: yup.string().required("Vennligst oppgi gatenavn og nummer"),
    postnummer: yup
      .string()
      .matches(/^[0-9]+$/, "Må være 4 siffre")
      .min(4, "Må være 4 siffre")
      .max(4, "Må være 4 siffre")
      .required("Vennligst oppgi postnummeret"),
    by: yup.string().required("Vennligst skriv inn bynavnet").default(poststed),
    passord: yup
      .string()
      .transform(function (value) {
        return bcrypt.hashSync(value, salt);
      })
      .required("Vennligst lag et passord")
      .min(5, "Minimum 5 tegn"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(data) {
    try {
      // axios.post('http://127.0.0.1:4000/api/post/opprett-bruker')
      

      const response = await axios.post(
        process.env.REACT_APP_TEST_URL + "/api/post/opprett-bruker",
        { data }, { withCredentials: true }
      );
      if (response.status === 200) {
        setTimeout(async () => {
          try {
            const idFetch = await axios.get(
              process.env.REACT_APP_TEST_URL + "/api/get/hent-id",
              { params: { epost: `${data.epost}` } }
            );
            if (idFetch.status === 200) {
              setLoggedIn(true);
              navigate("/finnrenholder");
            }
          } catch (err) {
            console.log(err);
          }
          }, 500) // Gives time for user to be made before requesting id, otherwise idFetch returns empty array
        }
      } catch (error) {
        document.getElementById("errorMsg").innerHTML= error.response.data;

        console.log("There was an error trying to create userprofile, with error: ", error)}
  }

  // Use geonorge api to fetch poststed from postnummer

  async function checkGeo(e) {
    const inputNumber = e.target.value.split(" ").join("");
    if (inputNumber.toString().length === 4 && !isNaN(inputNumber)) {
      try {
        const response = await axios.get(
          "https://ws.geonorge.no/adresser/v1/sok?treffPerSide=1&postnummer=" +
            inputNumber
        );
        if (response.data.adresser[0]) {
          setPostSted(response.data.adresser[0].poststed);
          setSubmitDisabled(false);
        } else {
          setPostSted("Ugyldig postnummer");
          setSubmitDisabled(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <div className="nykunde-page">
      <h1>Ny kunde</h1>
      <div className="nykunde-container">
        <form id="nykunde__form" onSubmit={handleSubmit(onSubmit)}>
          <div className="navn-container">
            <div className="form-item fornavn">
              <label htmlFor="fornavn">Fornavn</label>
              <input {...register("fornavn")} />
              {errors.fornavn && (
                <span className="form__error nykunde-form__error">
                  {errors.fornavn.message}
                </span>
              )}
            </div>
            <div className="form-item etternavn">
              <label htmlFor="etternavn">Etternavn</label>
              <input {...register("etternavn")} />
              {errors.etternavn && (
                <span className="form__error nykunde-form__error">
                  {errors.etternavn.message}
                </span>
              )}
            </div>
          </div>
          {/* <div className="contact-container"> */}
            <div className="form-item epost">
              <label htmlFor="epost">E-post</label>
              <input {...register("epost")} />
              {errors.epost && (
                <span className="form__error nykunde-form__error">
                  {errors.epost.message}
                </span>
              )}
            </div>
            <div className="form-item telefon">
              <label htmlFor="telefon">Telefon</label>
              <input {...register("telefon")} />
              {errors.telefon && (
                <span className="form__error nykunde-form__error">
                  {errors.telefon.message}
                </span>
              )}
            </div>
          {/* </div> */}
          <div className="adresse-container">
            <div className="form-item gate">
              <label htmlFor="adresseGate">Gatenavn og nummer</label>
              <input {...register("adresseGate")} />
              {errors.adresseGate && (
                <span className="form__error nykunde-form__error">
                  {errors.adresseGate.message}
                </span>
              )}
            </div>
            <div className="form-item postnummer">
              <label htmlFor="postnummer">Postnummer</label>
              <input {...register("postnummer")} onChange={checkGeo} />
              {errors.postnummer && (
                <span className="form__error nykunde-form__error">
                  {errors.postnummer.message}
                </span>
              )}
            </div>
          </div>
            <div className="form-item by">
              <label htmlFor="by">By</label>
              <input
                {...register("by")}
                value={poststed}
                type="input"
                disabled
              />
              {errors.by && (
                <span className="form__error nykunde-form__error">
                  {errors.by.message}
                </span>
              )}
            </div>
          <div className="form-item passord">
            <label htmlFor="passord">Passord</label>
            <input {...register("passord")} type="password" />
            {errors.passord && (
              <span className="form__error nykunde-form__error">
                {errors.passord.message}
              </span>
            )}
          </div>
          <Button disabled={submitDisabled}>Lag Bruker</Button>
        </form>
      </div>
      <div>
        <p id="errorMsg"></p>
      </div>
    </div>
  );
}
