import { noStars, halfStar, oneStar, oneHalfStar, twoStars, twoHalfStars, threeStars, threeHalfStars, fourStars, fourHalfStars, fiveStars } from '../icons/stars';

export default function rating(stars){
  let icon;
  let aria;
  switch(true){
    case undefined: icon = 'Urangert'; aria='Urangert';  break;
    case (stars < 0.5): icon = noStars; aria='Vurdering null stjerner'; break;
    case (stars < 1): icon = halfStar; aria='Vurdering halv stjerne'; break;
    case (stars < 1.5): icon = oneStar; aria='Vurdering en stjerne'; break;
    case (stars < 2): icon = oneHalfStar; aria='Vurdering en og halv stjerner'; break;
    case (stars < 2.5): icon = twoStars; aria='Vurdering to stjerner'; break;
    case (stars < 3): icon = twoHalfStars; aria='Vurdering to og halv stjerner'; break;
    case (stars < 3.5): icon = threeStars; aria='Vurdering tre stjerner'; break;
    case (stars < 4): icon = threeHalfStars; aria='Vurdering tre og halv stjerner'; break;
    case (stars < 4.5): icon = fourStars; aria='Vurdering fire stjerner'; break;
    case (stars <= 4.75): icon = fourHalfStars; aria='Vurdering fire og halv stjerner'; break;
    case (stars > 4.75): icon = fiveStars; aria='Vurdering fem stjerner'; break; 
    default: icon = '';
  }
  return([icon, aria]);
}