import { Link } from "react-router-dom";
import rating from "../../functions/rating.js";
import { dog } from "../../icons/dog.js";
import { blackCat } from "../../icons/black-cat.js";

export default function ProfileCard({
  id,
  img,
  name,
  allergihund,
  allergikatt,
  nextAppointment,
  price,
  stars,
}) {
  let icon = rating(stars)[0];
  let aria = rating(stars)[1];
  let profileLink = "/renholder/" + id;
  return (
    <div onClick={() => {}} className="profile-card">
      <div className="image-container">
        <img
          src={img}
          alt={name}
          // onError={(e) => {
          //   e.target.onerror = null;
          //   e.target.src = { profilePlaceholder };
          // }}
        />
      </div>
      <div className="profile__details">
        <h3 className="profile__name">{name}</h3>
        <div className="flexRow spaceBetween">
          <div className="profile__price">
            <h4>Timespris (inkl mva):</h4>
            {price} kr
          </div>
          <div className="profile__allergy">
            <h4>Allergier:</h4>
            {((allergihund == null && allergikatt == null) || (allergihund == 0 && allergikatt == 0)) ? <div>Ingen</div>:<div></div>}
            <div className="flexRow">

            {allergihund == 1 ? (
              <div className="allergyIcon">{dog}</div>
              ) : (
                <div></div>
                )}
            {allergikatt == 1 ? (
              <div className="allergyIcon">{blackCat}</div>
              ) : (
                <div></div>
                )}
                </div>
          </div>
        </div>
        <div className="profile__rating" aria-label={aria}>
          <h4>Vurdering: </h4>
          {icon}
        </div>
        <div className="profile__area">
          <h4>Neste ledig time:</h4>
          {nextAppointment}
        </div>
        <div className="profile__link link-container">
          <Link className="cta" to={profileLink}>
            Se profil
          </Link>
        </div>
      </div>
    </div>
  );
}
