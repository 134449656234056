import Bookings from "../components/global/Bookings"

export default function KundeBookings() {
  return(
    <div>
      <h1>Bookinger</h1>
      <Bookings />
    </div>
  )
}
