import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import stars from "../functions/rating";
import ChatWindow from "../components/global/ChatWindow";
import BestillingModal from "../components/global/BestillingModal";
import Cookies from "universal-cookie";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import profilePlaceholder from "../images/profilePlaceholder.jpg";

const cookies = new Cookies();

export default function Renholder() {
  const { id } = useParams();
  const role = "cleaner";
  const [user, setUser] = useState({});
  const [userId, setUserId] = useState();
  const [area2, setArea] = useState();
  const [fetched, setFetched] = useState(false);
  const [rating, setRating] = useState(0);
  const [profileImage, setProfileImage] = useState(
    "https://www.cmcaindia.org/wp-content/uploads/2015/11/default-profile-picture-gmail-2.png"
  );
  const [bookingDates, setBookingDates] = useState([]);
  const [ratings, setRatings] = useState();

  const [render, setRender] = useState(false);
  const [price, setPrice] = useState(0);
  const [cleaningHours, setCleaningHours] = useState();

  // let [tid, setTid] = useState(0)
  // let price;

  const [bookingType, setBookingType] = useState([]);
  const [vasktypeArray, setVaskTypeArray] = useState([]);
  let tempVasktypeArray = [];

  const [bookingDate, setBookingDate] = useState();
  const [bookingDateISO, setBookingDateISO] = useState();
  const [bookingDateStandard, setBookingDateStandard] = useState();
  let [notMatchingElements, setNotMatchingElements] = useState([]);
  const [bookingTimes, setBookingTimes] = useState([]);

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  let tempTid = 0;
  let today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  const [bookingTime, setBookingTime] = useState();
  const [bestillingModalOpen, setBestillingModalOpen] = useState(false);
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    checkCookie();
  }, []);

  useEffect(() => {
    // fetchNumberOfHours();
    fetchTypes();
  }, []);
  
  useEffect(() => {
    let userId = id;
    let areaArray = [];
    fetchBookings(userId, setBookings);
    fetchRatings(userId);
    getProfileInfo(userId, areaArray);
    checkTimes();
  }, [fetched]);

  async function fetchTypes() {
    await axios
      .get(process.env.REACT_APP_TEST_URL + "/api/get/fetch-bookingtypes")
      .then((response) => {
        let data = response.data;
        setBookingType(data);
      });
  }

  async function checkCookie() {
    let response = await axios.get(
      process.env.REACT_APP_TEST_URL + "/api/get/readCookie",
      { withCredentials: true }
    );

    if (response.data.id !== undefined) {
      setUserId(response.data.id);
    }
  }

  async function fetchRatings(userId) {
    
    if(userId !== undefined) {
      
      let response = await axios.post(
        process.env.REACT_APP_TEST_URL + "/api/get/getRating",
        { userId: userId },
        { withCredentials: true }
      );
      
      if (response.data) {
        setRatings(response.data);
      }
    }
  }

  function openBestillingModal() {
    vasktypeArray.forEach(async (element) => {
      const response = await axios
        .get(process.env.REACT_APP_TEST_URL + "/api/get/fetch-booking-type", {
          params: { element },
        })
        .then((response) => {
          if (response.status === 200) {
            tempTid += parseInt(response.data[0].estimatedTime);
            setPrice(user.hourlyPay * tempTid);
          }
        });
    });
    setTimeout(() => {
      setBestillingModalOpen(true);
    }, 400);
  }

  let icon = stars(rating)[0];
  let aria = stars(rating)[1];

  async function checkTimes() {
    let times = [];

    if (user.startTime !== null) {
      for (var i = user.startTime; i <= user.endTime; i++) {
        if (i < 10) {
          times.push(`0${i}:00:00`);
        } else {
          times.push(`${i}:00:00`);
        }
      }
    } else {
      for (var i = 8; i <= 16; i++) {
        if (i < 10) {
          times.push(`0${i}:00:00`);
        } else {
          times.push(`${i}:00:00`);
        }
      }
    }

    let selectedDateISO;
    if (!bookingDateISO) {
      let now = new Date().toISOString().split("T")[0];
      setBookingDateISO(now);
    } else {
      selectedDateISO = bookingDateISO.split("T")[0];
    }

    // Get the selected date without the time part

    // Filter bookings that match the selected date
    const bookingsOnSelectedDate = bookings.filter((booking) => {
      let newDate = new Date(booking.date);
      newDate = newDate.setDate(newDate.getDate() + 1);
      newDate = new Date(newDate).toISOString();
      return newDate.includes(selectedDateISO);
    });
    
    // Create an array of booked times on the selected date
    //add a way to filter through the times that the cleaner is busy
    let bookedTimesArray = [];
    const bookedTimes = bookingsOnSelectedDate.map((booking) => {
      let count;
      for (let index = 0; index < booking.estimatedTime; index++) {
        count = parseInt(booking.time.slice(0, 2)) + index;
        if (count < 10) {
          bookedTimesArray.push(`0${count}:00:00`);
        } else {
          bookedTimesArray.push(`${count}:00:00`);
        }
      }
    });
    
    // Filter available times that are not booked
    const availableTimes = times.filter((time) => {
      return !bookedTimesArray.includes(time);
    });
    // Update the state with available times
    setNotMatchingElements(availableTimes.map((time) => time.slice(0, 2)));
  }

  function radioChange(e) {
    let totalCount = 0;
    let difference = 0;
    let previousDifference = 0;
    let cleaningHours = 4; //change this with the dynamic hours of booking
    let inputs = document.querySelectorAll(".time-picker__input");
    let targetId = e.target.id;
    inputs.forEach((element, index) => {
      let differenceToLastIndex = inputs.length - index;
      difference = element.id - targetId;
      if (targetId === element.id) {
        if (
          (element.id == 8 && differenceToLastIndex >= cleaningHours) ||
          (difference - previousDifference === 1 &&
            differenceToLastIndex >= cleaningHours)
        ) {
          totalCount++;
        }
      }

      previousDifference = difference;
    });
    // if (totalCount === 0 && totalCount <= cleaningHours) {
    //   document.getElementById("feedbackError").style.display = "inline-block";
    // } else {
    //   document.getElementById("feedbackError").style.display = "none";
    // }
    inputs.forEach((input) => {
      input.previousElementSibling.classList.remove("on");
    });
    e.target.previousElementSibling.classList.add("on");
  }

  const handleCalendarChange = (e) => {
    // document.getElementById("feedbackError").style.display = "none";
    const selectedDate = e.toLocaleString("no-NO", options).slice(0, -4);
    setBookingDate(selectedDate);

    const nextDay = new Date(e.toISOString());
    nextDay.setDate(nextDay.getDate() + 1);
    const nextDayISO = nextDay.toISOString();
    setBookingDateISO(nextDayISO);

    const standardDate = e.toLocaleString("no-NO").slice(0, -10);
    setBookingDateStandard(standardDate);
  };

  // Use useEffect to trigger checkTimes when bookingDateISO changes
  useEffect(() => {
    checkTimes();
  }, [bookingDateISO]);

  if (window.location.search) {
    if (area2) {
      return (
        <div id="renholder-page">
          <h1>{user.firstName}</h1>
          <div className="renholder-details">
            <div className="image-container">
              <img src={user.profilephoto} alt="" />
            </div>
            <div className="text-container">
              <div className="area-container">
                {area2.areaArray.map((area) => {
                  return <span key={area}>{area}, </span>;
                })}
              </div>
              <div className="timespris">Timespris: {user.hourlyPay} kr</div>
              <div className="beskrivelse">Beskrivelse: {user.description}</div>
              <div className="vurderinger" aria-label={aria}>
                Andre kunders vurderinger: {icon}
              </div>
            </div>
          </div>
          <h2>Noen spørsmål til {user.firstName}?</h2>
          <div className="button-container">
            <button
              id="chat-button"
              onClick={(e) => {
                e.target.nextElementSibling.classList.add("visible");
              }}
            >
              Chat
            </button>
            <ChatWindow receiverId={id} senderId={userId} modal={true} />
          </div>
        </div>
      );
    }
  } else {
    if (area2) {
      return (
        <div id="renholder-page">
          <h1>{user.firstName}</h1>
          <div className="renholder-details">
            <div className="image-container">
              <img
                src={profileImage}
                alt=""
                // onError={(e) => {
                //   e.target.onerror = null;
                //   e.target.src = { profilePlaceholder };
                // }}
              />
            </div>
            <div className="text-container">
              <div className="area-container">
                {area2.areaArray.map((area) => {
                  return <span key={area}>{area} ¤ </span>;
                })}
              </div>
              <div className="timespris">Timespris: {user.hourlyPay}kr</div>
              <div className="beskrivelse">Beskrivelse: {user.description}</div>
              <div className="vurderinger" aria-label={aria}>
                Andre kunders vurderinger: {icon}
              </div>
            </div>
          </div>

          <h2>Noen spørsmål til {user.firstName}?</h2>
          <div className="button-container">
            <button
              id="chat-button"
              onClick={(e) => {
                e.target.nextElementSibling.classList.add("visible");
              }}
            >
              Chat
            </button>
            <ChatWindow receiverId={id} senderId={userId} modal={true} />
          </div>
          <div className="renholder-bestilling">
            <hr />
            <h2>Eller bestille nå:</h2>
            <h3>Velg type vask</h3>
            <div className="velg-vask-container">
              {bookingType.map((booking) => {
                return (
                  <button
                    className={"vask-type"}
                    // className={booking.bookingName? "vask-type selected" : "vask-type"}
                    id={booking.bookingName}
                    onClick={(e) => {
                      let id = e.target.id;
                      document.getElementById(id).classList.toggle("selected");
                      if (
                        !vasktypeArray.includes(id) &&
                        document
                          .getElementById(id)
                          .classList.contains("selected")
                      ) {
                        setVaskTypeArray([...vasktypeArray, id]);
                      }

                      if (
                        vasktypeArray.includes(id) &&
                        !document
                          .getElementById(id)
                          .classList.contains("selected")
                      ) {
                        setVaskTypeArray(
                          vasktypeArray.filter((element) => element !== id)
                        );
                      }
                    }}
                  >
                    {booking.bookingName}
                  </button>
                );
              })}

              {/* Buttons */}
            </div>
            {/* <div>
              <button
                className={utvask ? "vask-type selected" : "vask-type"}
                id="utvask"
                onClick={() => {
                  setUtvask(!utvask);
                }}
              >
                Utvask
              </button>
              <button
                className={husvask ? "vask-type selected" : "vask-type"}
                id="husvask"
                onClick={() => {
                  setHusvask(!husvask);
                }}
              >
                Husvask
              </button>
              <button
                className={vårrengjøring ? "vask-type selected" : "vask-type"}
                id="vårrengjøring"
                onClick={() => {
                  setVårrengjøring(!vårrengjøring);
                }}
              >
                Vårrengjøring
              </button>
              <button
                className={kjøkken ? "vask-type selected" : "vask-type"}
                id="kjøkken"
                onClick={() => {
                  setKjøkken(!kjøkken);
                }}
              >
                Kjøkken
              </button>
              <button
                className={overetasje ? "vask-type selected" : "vask-type"}
                id="overetasje"
                onClick={() => {
                  setOveretasje(!overetasje);
                }}
              >
                Overetasje
              </button>
              <button
                className={underetasje ? "vask-type selected" : "vask-type"}
                id="underetasje"
                onClick={() => {
                  setUnderetasje(!underetasje);
                }}
              >
                Underetasje
              </button>
              <button
                className={stua ? "vask-type selected" : "vask-type"}
                id="stua"
                onClick={() => {
                  setStua(!stua);
                }}
              >
                Stua
              </button>
              <button
                className={bad ? "vask-type selected" : "vask-type"}
                id="bad"
                onClick={() => {
                  setBad(!bad);
                }}
              >
                Bad
              </button>
            </div> */}
            <div className="info">
              Det blir estimert pris basert på type vask
            </div>
            <h3>Velg tidspunkt</h3>
            <div className="velg-tidspunkt-container">
              <Calendar
                onChange={(e) => {
                  handleCalendarChange(e);
                  setBookingDate(
                    e.toLocaleString("no-NO", options).slice(0, -4)
                  );

                  let selecteddate = new Date(e.toISOString());
                  selecteddate = selecteddate.setDate(
                    selecteddate.getDate() + 1
                  );
                  selecteddate = new Date(selecteddate).toISOString();
                  setBookingDateISO(selecteddate);
                  //need datostandard for the standard date to be saved in mysql

                  setBookingDateStandard(
                    e.toLocaleString("no-NO").slice(0, -10)
                  );
                  checkTimes();
                }}
                // tileDisabled={({ date }) =>
                //   bookingDates.some(
                //     (bookingDate) =>
                //       date.getFullYear() === bookingDate.getFullYear() &&
                //       date.getMonth() === bookingDate.getMonth() &&
                //       date.getDate() === bookingDate.getDate()
                //   )
                // }

                locale="no-NO"
                minDate={new Date()}
                maxDate={
                  new Date(new Date().setFullYear(new Date().getFullYear() + 1))
                }
              />
            </div>
            <div className="time-picker">
              <div>{bookingDate ? bookingDate : "Ingen dato valgt"}</div>
              <form
                onChange={(e) => {
                  setBookingTime(e.target.id);
                }}
              >
                <h4>Velg en starttid:</h4>
                <div className="times">
                  {/* <p id="feedbackError">
                    Rengjører har dessverre ikke nok tid til å gjøre seg ferdig,
                    velg gjerne en annen dato eller en annen tidspunkt
                  </p> */}
                  {notMatchingElements.map((number) => {
                    // {render ? notMatchingElements.map((number) => {
                    return (
                      <div key={number}>
                        <label htmlFor={number}>{number}</label>
                        <input
                          className="time-picker__input"
                          onChange={(e) => {
                            radioChange(e);
                          }}
                          type="radio"
                          id={number}
                          name="time"
                        />
                      </div>
                    );
                  })}
                  {/* }) : null} */}
                </div>
              </form>
            </div>
            <div className="button-container">
              <button
                className="book-cleaner"
                onClick={() => {
                  openBestillingModal();
                }}
              >
                Bestill {user.firstName}
              </button>
            </div>

              <h2>Vurderinger</h2>
              {rating.length > 0 ? 
            <div className="flexRow">
              {ratings.map((rating) => {
                return (
                  <div id={rating.id} className="flexColumn">
                    <p>{rating.ratingDesc}</p>
                    <em>~ {rating.firstName}</em>
                  </div>
                );
              })}
            </div>
              :<p>Ingen vurderinger enda</p>}
            <BestillingModal
              rengjører={user}
              vasktype={vasktypeArray}
              dato={bookingDate}
              //need datostandard for the standard date to be saved in mysql

              datoStandard={bookingDateStandard}
              starttid={bookingTime}
              open={bestillingModalOpen}
              price={price}
              onClose={() => {
                setBestillingModalOpen(false);
              }}
            />
          </div>
        </div>
      );
    }
  }

  async function fetchNumberOfHours() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_TEST_URL + "/api/get/fetch-cleaning-hours"
      );

      setCleaningHours(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchBookings(userId) {
    try {
      const response = await axios.get(
        process.env.REACT_APP_TEST_URL + "/api/get/fetch-bookings-cleaner",
        { params: { userId, role } }
      );
      if (response.data) {
        setBookings(response.data);

        response.data.forEach((element) => {
          let newDate = new Date(element.date);
          bookingDates.push(newDate);
        });
      }
    } catch (error) {}
  }

  async function getProfileInfo(userId, areaArray) {
    try {
      const response = await axios.get(
        process.env.REACT_APP_TEST_URL + "/api/get/fetch-user",
        { params: { userId, role } }
      );
      if (response.status === 200) {
        setUser(response.data[0]);
        if (user.currentRating !== null) {
          setRating(parseFloat(user.currentRating));
        } else {
          setRating(parseFloat(0));
        }
        if (user.profilephoto && user.profilephoto.length > 0) {
          setProfileImage(user.profilephoto);
        }
      }
      const response2 = await axios.get(
        process.env.REACT_APP_TEST_URL + "/api/get/fetch-area",
        { params: { userId, role } }
      );
      if (response.status === 200) {
        response2.data.forEach((element) => {
          areaArray.push(element.cityName);
        });
        setArea({ areaArray });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setFetched(true);
    }
  }
}
