import axios from "axios";
import SHA256 from "crypto-js/sha256";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState, useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "../components/micro/Button";
import Cookies from "universal-cookie";
import AuthContext from "../context/AuthContext";

var bcrypt = require("bcryptjs");
var salt = bcrypt.genSaltSync(10);

const cookies = new Cookies();

export default function NyRenholder() {
  const [poststed, setPostSted] = useState("");
  const [loggedIn, setLoggedIn] = useContext(AuthContext);
  const schema = yup.object().shape({
    fornavn: yup.string().required("Vennligst skriv inn navnet ditt"),
    etternavn: yup.string().required("Vennligst skriv inn etternavnet ditt"),
    epost: yup
      .string()
      .required("Vennligst oppgi eposten din")
      .email("Vennligst skriv inn en gyldig e-postadresse"),
    telefon: yup.string().required("Vennligst oppgi telefonnummeret ditt"),
    orgnummer: yup
      .string()
      .required("Vennligst oppgi organisasjonsnummeret ditt")
      .matches(/^[0-9 ]+$/, "Må være 9 siffre")
      .min(9, "Må være 9 siffre"),
    kontonummer: yup.string().required("Vennligst oppgi kontonummeret ditt"),
    adresseGate: yup.string().required("Vennligst oppgi gatenavn og nummer"),
    postnummer: yup
      .string()
      .matches(/^[0-9]+$/, "Må være 4 siffre")
      .min(4, "Må være 4 siffre")
      .max(4, "Må være 4 siffre")
      .required("Vennligst oppgi postnummeret"),
    by: yup.string().required("Vennligst skriv inn bynavnet").default(poststed),
    passord: yup
      .string()
      .transform(function (value) {
        return bcrypt.hashSync(value, salt);
      })
      .required("Vennligst lag et passord"),
  });

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  async function onSubmit(data) {
    if (submitDisabled) {
      document.querySelector("#orgnummer").focus();
      setRejectedOrgNummer("Vennligst oppgi et gyldig organisasjonsnummer");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_URL}/api/post/opprett-bruker`,
        { data }, { withCredentials: true }
      );
      if (response.status === 200) {
        setTimeout(async () => {
          try {
            const idFetch = await axios.get(
              process.env.REACT_APP_TEST_URL + "/api/get/hent-id",
              { params: { epost: `${data.epost}` } }
            );
            if (idFetch.status === 200) {
              cookies.set("id", idFetch.data[0].iduser, { path: "/" });
              cookies.set("role", "cleaner", { path: "/" });
              let sessionId =
                idFetch.data[0].iduser +
                idFetch.data[0].firstName +
                idFetch.data[0].lastName;
              //if this one works, it has to be added to nykunde.jsx as well
              cookies.set("sessionid", SHA256(sessionId).toString, {
                path: "/",
              });
              setLoggedIn(true);
              navigate("/profil/");
            }
          } catch (err) {
            console.log(err);
          }
        }, 800); // Gives time for user to be made before requesting id, otherwise idFetch returns empty array
      }
    } catch (error) {
      console.log(
        "There was an error trying to create userprofile, with error: ",
        error
      );
    }
  }

  // Check Brønnøysundregistrene to see if organisation number is registered there, and if the company is bankrupt or being forcibly broken up.
  const [checkingOrgNummer, setCheckingOrgNummer] = useState(false);
  const [confirmedOrgNummer, setConfirmedOrgNummer] = useState("");
  const [rejectedOrgNummer, setRejectedOrgNummer] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  async function checkReg(e) {
    setRejectedOrgNummer("");
    setConfirmedOrgNummer("");
    const inputNumber = parseInt(e.target.value.split(" ").join(""));
    if (inputNumber.toString().length === 9 && !isNaN(inputNumber)) {
      setCheckingOrgNummer(true);
      errors.orgnummer = "";
      try {
        const response = await axios.get(
          "https://data.brreg.no/enhetsregisteret/api/enheter/" + inputNumber
        );
        if (
          response.status === 200 &&
          !response.data.konkurs &&
          !response.data.underTvangsavviklingEllerTvangsopplosning
        ) {
          setCheckingOrgNummer(false);
          setSubmitDisabled(false);
          setRejectedOrgNummer("");
          setConfirmedOrgNummer(
            `${response.data.navn} godkjent med Brønnøysundregistrene`
          );
        }
        if (
          response.data.konkurs ||
          response.data.underTvangsavviklingEllerTvangsopplosning
        ) {
          setCheckingOrgNummer(false);
          setSubmitDisabled(true);
          setRejectedOrgNummer(
            `${response.data.navn} er konkurs eller under tvangsavvikling eller tvangsoppløsning`
          );
        }
      } catch (error) {
        if (error.response.status === 404) {
          setCheckingOrgNummer(false);
          setRejectedOrgNummer("Vennligst oppgi et gyldig organisasjonsnummer");
          setSubmitDisabled(true);
        } else {
          setRejectedOrgNummer(
            "Beklager, en feil har oppstått med org nummer godkjenning. Vennligst prøv igjen."
          );
        }
      }
    } else {
      setCheckingOrgNummer(false);
      setConfirmedOrgNummer("");
    }
  }

  // Use geonorge api to fetch poststed from postnummer

  async function checkGeo(e) {
    const inputNumber = e.target.value.split(" ").join("");
    if (inputNumber.toString().length === 4 && !isNaN(inputNumber)) {
      try {
        const response = await axios.get(
          "https://ws.geonorge.no/adresser/v1/sok?treffPerSide=1&postnummer=" +
            inputNumber
        );
        if (response.data.adresser[0]) {
          setPostSted(response.data.adresser[0].poststed);
        } else {
          setPostSted("Ugyldig postnummer");
          setSubmitDisabled(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <div className="nyrenholder-page">
      <h1>Ny renholder</h1>
      <div className="nyrenholder-container">
        <form id="nyrenholder__form" onSubmit={handleSubmit(onSubmit)}>
          <div className="navn-container">
            <div className="form-item fornavn">
              <label htmlFor="fornavn">Fornavn</label>
              <input {...register("fornavn")} />
              {errors.fornavn && (
                <span className="form__error">{errors.fornavn.message}</span>
              )}
            </div>
            <div className="form-item etternavn">
              <label htmlFor="etternavn">Etternavn</label>
              <input {...register("etternavn")} />
              {errors.etternavn && (
                <span className="form__error">{errors.etternavn.message}</span>
              )}
            </div>
          </div>
          <div className="contact-container">
            <div className="form-item epost">
              <label htmlFor="epost">E-post</label>
              <input {...register("epost")} />
              {errors.epost && (
                <span className="form__error">{errors.epost.message}</span>
              )}
            </div>
            <div className="form-item telefon">
              <label htmlFor="telefon">Telefon</label>
              <input {...register("telefon")} />
              {errors.telefon && (
                <span className="form__error">{errors.telefon.message}</span>
              )}
            </div>
          </div>
          <div className="form-item orgnummer">
            <label htmlFor="orgnummer">Org. nummer</label>
            <input
              id="orgnummer"
              {...register("orgnummer")}
              onChange={checkReg}
            />
            {errors.orgnummer && (
              <span className="form__error">{errors.orgnummer.message}</span>
            )}
            {checkingOrgNummer && <span>Sjekker...</span>}
            {confirmedOrgNummer && (
              <span
                style={{ height: 35, marginTop: 5, display: "inline-block" }}
              >
                {confirmedOrgNummer}
              </span>
            )}
            {rejectedOrgNummer && (
              <span style={{ color: "firebrick" }}>{rejectedOrgNummer}</span>
            )}
          </div>
          <div className="form-item kontonummer">
            <label htmlFor="kontonummer">Kontonummer</label>
            <input {...register("kontonummer")} />
            {errors.kontonummer && (
              <span className="form__error">{errors.kontonummer.message}</span>
            )}
          </div>
          <div className="adresse-container">
            <div className="form-item gate">
              <label htmlFor="adresseGate">Gatenavn og nummer</label>
              <input {...register("adresseGate")} />
              {errors.adresseGate && (
                <span className="form__error">
                  {errors.adresseGate.message}
                </span>
              )}
            </div>
            <div className="form-item postnummer">
              <label htmlFor="postnummer">Postnummer</label>
              <input {...register("postnummer")} onChange={checkGeo} />
              {errors.postnummer && (
                <span className="form__error">{errors.postnummer.message}</span>
              )}
            </div>
            <div className="form-item by">
              <label htmlFor="by">By</label>
              <input
                {...register("by")}
                value={poststed}
                type="input"
                disabled
              />
              {errors.by && (
                <span className="form__error">{errors.by.message}</span>
              )}
            </div>
          </div>
          <div className="form-item passord">
            <label htmlFor="passord">Passord</label>
            <input {...register("passord")} type="password" />
            {errors.passord && (
              <span className="form__error">{errors.passord.message}</span>
            )}
          </div>
          <Button>Lag Bruker</Button>
        </form>
      </div>
    </div>
  );
}
