import axios from 'axios'
import { useState } from 'react'
import ProfileCard from './ProfileCard'
import CheckoutForm from '../stripe/CheckoutForm'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'


// import CheckoutForm from "../stripe/CheckoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPEKEY);

export default function BestillingModal({
  rengjører,
  vasktype,
  dato,
              //need datostandard for the standard date to be saved in mysql

  datoStandard,
  starttid,
  open,
  onClose,
  price,
}) {
  const [repeat, setRepeat] = useState()
  const [stripeSCState, setStripeSCState] = useState()
  const [stripeForm, setStripeForm] = useState(false)
  const [clientSecret, setCLientSecret] = useState()
  

  async function stripePayment() {

    let priceInCents = price * 100;
    let response2 = await axios.post(process.env.REACT_APP_TEST_URL+'/api/post/stripePayment', {amount: priceInCents, repeat: repeat});

        setStripeSCState(response2.data.stripeCS)


        if (stripePromise) {
          setStripeForm(true)
        }
  }
  const options = {
    // passing the client secret obtained in step 3
    clientSecret: stripeSCState,
    // Fully customizable with appearance API.
    appearance: {},
  }

  if (stripeForm) {
    return (
      <div className={open ? 'bestilling-modal open' : 'bestilling-modal'}>
        <div className="bestilling-modal__content">
          <button
            className="close-button"
            onClick={() => {
              onClose()
              setStripeForm(false)
            }}
          >
            Lukk
          </button>
          <h2>Bestille {rengjører.firstName}</h2>
          <div>
            <span className="bestille-desc">Vasktype: </span>
            {vasktype.map((type) => {
              return <span key={type}>{type} </span>
            })}
          </div>
          <div>
            <span className="bestille-desc">Dato: </span>
            {dato}
          </div>
          <div>
            <span className="bestille-desc">Starttid: </span>kl {starttid}
          </div>
          <div>
            <span className="bestille-desc">Pris: </span>
            {price}kr
          </div>
          <div>
            <span className="bestille-desc">Frekvens: </span>
            {repeat}
          </div>
          <p id="errorMessagePayment"></p>
          <div id="payment-confirmation">
          Betalingen er godkjent. Du får snart en bekreftelse via epost.
        </div>
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              date={dato}
              //need datostandard for the standard date to be saved in mysql
              datoStandard={datoStandard}
              time={starttid}
              cleaner={rengjører}
              typeOfCleaning={vasktype}
              repeat={repeat}
              price={price}
            />
          </Elements>
        </div>
      </div>
    )
  }

  return (
    <div className={open ? 'bestilling-modal open' : 'bestilling-modal'}>
      <div className="bestilling-modal__content">
        <button
          className="close-button"
          onClick={() => {
            onClose()
          }}
        >
          Lukk
        </button>
        <h2>Bestille {rengjører.firstName}</h2>
        <div>
          <span className="bestille-desc">Vasktype: </span>
          {vasktype.map((type) => {
            return (
              <span className="vasktype" key={type}>
                {type}{' '}
              </span>
            )
          })}
        </div>
        <div>
          <span className="bestille-desc">Dato: </span>
          {dato}
        </div>
        <div>
          <span className="bestille-desc">Starttid: </span>kl {starttid}
        </div>
        <div>
          <span className="bestille-desc">Pris: </span>
          {price} kr
        </div>
        <form className="gjentagende">
          <label htmlFor="gjentagende-select">Gjentagende:</label>
          <select
            name="gjenta"
            id="gjentagende-select"
            onChange={(e) => {
              let frekvens = e.target.value;
              if(frekvens === "uke") {
                setRepeat("hver uke")
              } else if (frekvens === "ikke") {
                setRepeat("ikke gjentagende")
              } else if(frekvens === "touker") {
                setRepeat("hver 2. uke")
              } else if(frekvens === "fireuker") {
                setRepeat("hver 4. uke")
              }
            }}
          >
            <option value="">--Vennligst velg--</option>
            <option value="ikke">Ikke gjentagende</option>
            <option value="uke">Hver uke</option>
            <option value="touker">Hver 2. uke</option>
            <option value="fireuker">Hver 4. uke</option>
          </select>
        </form>
        <div className="button-container">
          <button onClick={stripePayment}>Betal med Stripe</button>
        </div>

      </div>
    </div>
  )
}
