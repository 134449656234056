import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

export default function CheckoutForm({
  date,
  datoStandard,
  time,
  cleaner,
  typeOfCleaning,
  repeat,
  price,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [userId, setUserId] = useState();
  const [email, setEmail] = useState();

  let id = window.location.origin;
  let id2 = window.location.pathname;

  let customerId = userId;

  useEffect(() => {
    checkCookie();
  }, []);

  async function checkCookie() {
    let response = await axios.get(
      process.env.REACT_APP_TEST_URL + "/api/get/readCookie",
      { withCredentials: true }
    );
    

    if (response.data.id !== undefined) {
      setUserId(response.data.id);
    }
    if (response.data.email !== undefined) {
      setUserId(response.data.email);
    }
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    document.getElementById("stripePayment").innerHTML = "Behandler";
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {},
      redirect: "if_required",
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      document.getElementById("errorMessagePayment").innerHTML(result.error.message);
    } else {
      if (result.paymentIntent.status === "succeeded") {
        let resultDb = axios
          .post(process.env.REACT_APP_TEST_URL + "/api/post/paymentSucceeded", {
            customer: customerId,
            paymentId: result.paymentIntent.id,
            amount: result.paymentIntent.amount,
            date: date,
            time: time,
            //need datostandard for the standard date to be saved in mysql
            datoStandard: datoStandard,
            typeOfCleaning: typeOfCleaning,
            cleaner: cleaner,
            repeat: repeat,
          })
          .then((resultDb) => {
            if (resultDb.data === "success") {
              setSuccess(true);
              //SHOW A SUCCESS MESSAGE
            }
          });
      }

      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  if (success) {
    axios
      .post(
        process.env.REACT_APP_TEST_URL + "/api/post/send-payment-verification",
        {
          email: email,
          renholder: cleaner,
          vasktype: typeOfCleaning,
          dato: date,
          starttid: time,
          pris: price,
          frekvens: repeat,
        }
      )
      .then((response) => {
        if(response.data == "OK") {
          document.getElementById("payment-confirmation").style.display="inline-block"
          setTimeout(() => {
           window.location.replace("/bookings");
        }, 600);

        }
      });


  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button id="stripePayment" disabled={!stripe}>Betal</button>
    </form>
  );
}

// export default CheckoutForm;
