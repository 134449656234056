// Chat window that expands when a cleaner views a conversation
import axios from "axios";
import { useState, useEffect } from "react";
import Cookies from "universal-cookie";

export default function ChatWindow({
  senderId,
  receiverId,
  modal,
  showUnread,
}) {
  const [fetched, setFetched] = useState(false);
  const [messages, setMessages] = useState([]);
  const [id, setId] = useState([]);
  // let cookies = new Cookies();

  useEffect(() => {
    checkCookie();
  }, []);

  async function checkCookie() {
    let response = await axios.get(
      process.env.REACT_APP_TEST_URL + "/api/get/readCookie",
      { withCredentials: true }
    );
  
    if(response.data.id !== undefined) {
      setId(response.data.role)
    }
  }




  useEffect(() => {
    async function fetchChatHistory() {
      let response2 = await axios.get(
        process.env.REACT_APP_TEST_URL + "/api/get/fetch-unread-messages",
        { params: { idSender: senderId, idReceiver: receiverId } }
      );
      try {
        let response = await axios.get(
          process.env.REACT_APP_TEST_URL +
            "/api/get/fetch-messages-cleaner-site",
          { params: { idSender: senderId, idReceiver: receiverId } }
        );
        if (response.data) {

          setMessages(
            response.data[0].sort(function (a, b) {
              return new Date(b.date) - new Date(a.date);
            })
          ); //  not sure if this is the most efficient but it works
          //this needs to be added only when it is the other person reading the messages somehow,
          //trying therefore to add a check to see that the sender is not the same person as the person
          //logged in. Needs to be double checked if the axios post request is at the correct place and
          //if it runs on all messages that are being opened.
          if (response.data.senderid !== id) {
            response.data.forEach(async (element) => {
              element.forEach(async (element2) => {
                if (element2.status === 0) {
                  let response2 = await axios.post(
                    process.env.REACT_APP_TEST_URL + "/api/post/mark-as-read",
                    { id: element2.idmessages }
                  );
                }
              });
            });
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setFetched(true);
      }
    }
    fetchChatHistory();
  }, [fetched]);

  async function handleSend(e) {
    let text = e.target[0].value;
    try {
      let response = await axios.get(
        process.env.REACT_APP_TEST_URL + "/api/post/send-message",
        {
          params: { idSender: senderId, idReceiver: receiverId, message: text },
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      setFetched(!fetched);
      e.target[0].value = "";
    }
  }

  async function closeModal(e) {

    if(e.target.className == "close-button") {
      document.getElementsByClassName("chat-modal")[0].style.display = "none"
      e.currentTarget.parentElement.parentElement.classList.remove("visible");
    }
  }

  // check if modal (for finnreholder page) otherwise return chat window (for messages for both cleaner and customer)
  if (modal) {
    return (
      <div className="chat-modal-wrapper">
        <div className="chat-modal">
          <div className="modal-bg"></div>
          <span
            className="close-button"
            onClick={(e) => closeModal(e)}
            
            
            
          ></span>
          <div className="chat-history">
            {messages.map((message, idx) => {
              return (
                <div
                  key={idx}
                  className={
                    message.senderid.toString() === senderId ? "me" : "you"
                  }
                >
                  <div className="chat-item">{message.message}</div>
                </div>
              );
            })}
          </div>
          <form onSubmit={handleSend} action="javascript:void(0)">
            <input type="text" placeholder="Skriv en melding..." />
            <button>Send</button>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div className="chat-window">
        <div className="chat-history">
          {messages.map((message) => {
            // message.map((mess) => {
            return (
              <div
                key={message.idmessages}
                className={
                  message.senderid.toString() === senderId ? "me" : "you"
                }
              >
                <div className="chat-item ">{message.message}</div>
              </div>
            );
          })}
        </div>
        <form onSubmit={handleSend} action="javascript:void(0)">
          <input type="text" />
          <button>Send</button>
        </form>
      </div>
    );
  }
}
