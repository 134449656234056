import notFoundImage from "../images/404page.png"


export default function NotFound(params) {
  return (
    <div className="notFound">
      <img className="" src={notFoundImage}></img>
    </div>
  )
};
