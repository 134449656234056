import axios from "axios";
import { useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { add } from "../../icons/add";

export default function AreaForm({ dataName, label }) {
  const [dropdownItems, setDropdownItems] = useState([]);
  const [userId, setUserId] = useState();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    checkCookie();
  }, []);

  async function checkCookie() {
    let response = await axios.get(
      process.env.REACT_APP_TEST_URL + "/api/get/readCookie",
      { withCredentials: true }
    );

    // if (response.data.jwt !== undefined) {
    //   setLoggedIn(true);
    // }
    // if(response.data.role !== undefined) {
    //   setRole(response.data.role)
    // }

    if (response.data.id !== undefined) {
      setUserId(response.data.id);
    }
  }

  async function checkArea(e) {
    const inputNumber = e.target.value;
    let areaList = [];
    if (inputNumber.length > 1) {
      try {
        const response = await axios.get(
          `https://ws.geonorge.no/kommuneinfo/v1/sok?knavn=${inputNumber}*`
        );
        if (response.status === 200) {
          response.data.kommuner.forEach((element) => {
            areaList.push(element.kommunenavn);
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setDropdownItems(areaList);
      }
    } else {
      setDropdownItems(areaList);
    }
  }

  async function addKommune(kommune) {
    try {
      const response = await axios.post(
        process.env.REACT_APP_TEST_URL + "/api/post/oppdatere-omrade",
        { område: kommune, id: userId }
      );
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.log(
        "There was an error trying to add kommune, with error: ",
        error
      );
    }
  }

  return (
    <form className="area-form">
      <div>
        <label htmlFor={dataName}>{label}:</label>
        <input
          {...register(`${dataName}`)}
          placeholder="Skriv inn sted her"
          onChange={checkArea}
          autoComplete="off"
        />
      </div>
      <div className="search-dropdown">
        <ul className="dropdown-list">
          {dropdownItems.map((kommune) => {
            return (
              <li className="dropdown-list__item" key={kommune}>
                <span className="kommune">{kommune}</span>
                <span
                  className="add"
                  type="button"
                  onClick={() => {
                    addKommune(kommune);
                  }}
                >
                  {add}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </form>
  );
}
