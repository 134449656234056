import axios from 'axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import Dustpan from '../images/mobile-hero-image.jpg'
import { useState, useContext } from 'react'
import AuthContext from '../context/AuthContext'




const cookies = new Cookies()

let SHA256 = require('crypto-js/sha256')

const schema = yup.object().shape({
  epost: yup
    .string()
    .required('Vennligst oppgi eposten din')
    .email('Vennligst skriv inn en gyldig e-postadresse'),
})

export default function GlemtPassord() {
  const navigate = useNavigate()
  const [emailSuccess, setEmailSuccess] = useState(false)
  const [emailError, setEmailError] = useState('')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  async function onSubmit(data) {
    try {
      const idFetch = await axios.get(process.env.REACT_APP_TEST_URL+'/api/get/glemt-passord', {
        params: { epost: `${data.epost}` },
      })
      if (idFetch.data.successful) {
        setEmailSuccess(true)
      } else {
        setEmailError('Kan ikke finne brukeren, lag gjerne en ny bruker');
      }
    } catch (error) {
      console.log(error)
    }
  }

  if(emailSuccess){
    return(
      <div className="logginn-page">
        <h1>Glemt passord?</h1>
        <div className="logginn-container">
          <div className="logginn__img-container">
            <img src={Dustpan} alt="feiebrett som henger på veggen" />
          </div>
          <div>
            <p className='sjekk-epost'>Vennligst sjekk eposten din og følg linken i eposten for å oppdatere passordet</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="logginn-page">
      <h1>Glemt passord?</h1>
      <div className="logginn-container">
        <div className="logginn__img-container">
          <img src={Dustpan} alt="feiebrett som henger på veggen" />
        </div>
        {/* <form id="logginn__form"> */}
        <form id="logginn__form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-item">
            <label htmlFor="epost">E-post</label>
            <input {...register('epost')} />
            {errors.epost && (
              <span className="form__error logginn-form__error">
                {errors.epost.message}
              </span>
            )}
            {emailError && (
              <span className="form__error logginn-form__error">
                {emailError}
              </span>
            )}
          </div>

          <div className="button-container">
            <button>Send epost</button>
          </div>
        </form>
      </div>
    </div>
  )
}
