import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../images/logo2.png";
import logo3 from "../../images/logo3.png";
import Cookies from "universal-cookie";
import Button from "../micro/Button";
import { messagesIcon } from "../../icons/messages";
import { profileIcon } from "../../icons/profile";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import axios from "axios";

export default function Navbar() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [role, setRole] = useState();
  const navigate = useNavigate();
  const [jwt, setJwt] = useState();

  useEffect(() => {
    checkCookie();
  });

  async function checkCookie() {
    let response = await axios.get(
      process.env.REACT_APP_TEST_URL + "/api/get/readCookie",
      { withCredentials: true }
    );
    if (response.data.jwt !== undefined) {
      setLoggedIn(true);

      

      
      

    } else {

      // window.location.replace("/logginn")
    }
    
    if(response.data.role !== undefined) {
      setRole(response.data.role)
    }
  }

  async function logout() {
    /*send a request to the server*/
    axios
      .get(process.env.REACT_APP_TEST_URL + "/api/post/deleteCookie", {
        withCredentials: true,
      })
      .then((result) => {
        if (result.data) {
          setLoggedIn(false);
          window.location.replace("/");
        }
      });
      }

  

  function toggleHamburger(e) {
    e.currentTarget.parentElement.classList.toggle("open-menu");
    if(e.currentTarget.parentElement.classList.value == "open-menu") {

      document.getElementById("mobileMeny").style.display="block";
    } else {
      document.getElementById("mobileMeny").style.display="none";

    }
  
  }

  if (loggedIn && role === "customer") {
    return (
      <nav>
        <Link to="/" className="navbar__title">
          <img src={logo3} alt="logo" />
          Housemaids
        </Link>
        <button onClick={toggleHamburger} id="hamburger">
          <span className="burger-top burger-bit"></span>
          <span className="burger-middle1 burger-bit"></span>
          <span className="burger-middle2 burger-bit"></span>
          <span className="burger-bottom burger-bit"></span>
        </button>
        <ul className="desktopMenu" id="mobileMeny">
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "navLinkActive" : undefined
              }
              to="finnrenholder"
            >
              Finn Renholdere
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "navLinkActive" : undefined
              }
              to="bookings"
            >
              Bookinger
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "navLink-messages--active navLink-messages"
                  : "navLink-messages"
              }
              to="messages"
            >
              {messagesIcon}
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "navLink-profile--active navLink-profile"
                  : "navLink-profile"
              }
              to="profil"
            >
              {profileIcon}
            </NavLink>
          </li>
          <li className="navbar__logout">
            <Button onClick={logout}>Logg av</Button>
          </li>
        </ul>
      </nav>
    );
  }

  if (loggedIn && role === "cleaner") {
    return (
      <nav>
        <Link to="/" className="navbar__title">
          <img src={logo3} alt="logo" />
          Housemaids
        </Link>
        <button onClick={toggleHamburger} id="hamburger">
          <span className="burger-top burger-bit"></span>
          <span className="burger-middle1 burger-bit"></span>
          <span className="burger-middle2 burger-bit"></span>
          <span className="burger-bottom burger-bit"></span>
        </button>
        <ul className="desktopMenu" id="mobileMeny">
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "navLinkActive" : undefined
              }
              to="dashboard"
            >
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "navLinkActive" : undefined
              }
              to="profil"
            >
              Profil
            </NavLink>
          </li>
          <li className="navbar__logout">
            <Button onClick={logout}>Logg av</Button>
          </li>
        </ul>
      </nav>
    );
  }

  return (
    <nav>
      <Link to="/" className="navbar__title">
        <img src={logo3} alt="logo" />
        Housemaids
      </Link>
      <ul className="desktopMenu">
        <li>
          <NavLink className="navbar__login" to="logginn">
            Logg inn
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}
