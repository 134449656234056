import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import bloggImage from "../images/desktop-hero-image.jpg"
import axios from "axios";

export default function Ressurser() {
  const [blogs, setBlogs] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getBlogs()
    window.scrollTo(0, 0);

  }, [])

  async function getBlogs() {
    axios.post(process.env.REACT_APP_TEST_URL + "/api/get/getBlogs").then((result) => {
      if(result.data.length > 0) {
        setBlogs(result.data)
        setIsLoaded(true)

      }
    })
  }

  function sendBlog(blog) {
    navigate(`${blog.param}`)
  }


  
  return (
    <div>
      <h1>Ressurser</h1>

 <div className="bloggarticles">
{isLoaded  ? 
        blogs.map((blog) => {
          
          return (
            <div className="bloggarticle" id={blog.idblogg}>
            <img src={bloggImage}></img>
            <h2>{blog.metatitle}</h2>
            <p>{blog.metadesc}</p>
            <button onClick={() => sendBlog(blog)}>Les mer</button>
          </div>
    
          )
        })
        : <div>Ingen artikler dessverre</div>}
      </div> 
    </div>
  );
}
